/**
 * jsPDF doc settings
 */
export const jsDOC = {
  orientation: "p",
  unit: "cm",
  compress: true,
};
/**
 * Font
 */
export const MAIN_HEADER_SIZE = 18;
export const SUB_HEADER_SIZE = 14;
export const REGULAR_SIZE = 12;
//Note: jsPDF offers: Times, Courier, Helvetica
export const FONT = "Helvetica";

export const HEADER_FONT_SIZE = 8;
export const FOOTER_FONT_SIZE = 8;

export const DIMENSION_HEADER_SIZE = 15;

/**
 * Vertical position/spacing
 */
export const RESET_VERT_POS = 1.6;
export const INITIAL_VERT_POS = 2.5;
export const TITLE_VERT_SPACING = 0.8;
export const INTRO_PARA_SPACING_1 = 1.4;
export const INTRO_PARA_SPACING_2 = 0.8;
export const DEFAULT_SPACING = 1.5;
export const PARA_SPACING = 1;
export const NEW_LINE_MULTIPLIER = 0.5;
export const HYPERLINK_NEWLINE_SPACING = 0.45;
//export const

/**
 * Page dimension
 */
export const PAGE_WIDTH = 21;
export const PAGE_HEIGHT = 29.74;

/**
 * Concordia logo
 */
export const CONCORDIA_LOGO_LENGTH = 5.4;
export const CONCORDIA_LOGO_WIDTH = 1.8;
/**
 * Margins
 */
export const LEFT_MARGIN_HEADER = 1.8;
export const LEFT_MARGIN_DEF = 2.2;
export const BOTTOM_MARGIN_DEF = 2;

export const MARGIN_TOP = INITIAL_VERT_POS / 3;
export const MARGIN_LEFT = PAGE_WIDTH / 20;
export const MARGIN_BOTTOM = PAGE_HEIGHT - RESET_VERT_POS / 2;
export const MARGIN_RIGHT = (18.5 * PAGE_WIDTH) / 20;

/**
 * Chart
 */
export const CENTER_CHART = -2.5;
export const WIDTH_CHART = 1.2 * PAGE_WIDTH;
export const HEIGHT_CHART = 13;
export const CHART_VERTICAL_ADJUSTMENT = 14.2;

/**
 * Dimension content include header box, image and lists/text
 */
export const WHITE = "rgb(255,255,255)";
export const BLACK = "rgb(0,0,0)";
export const BLUE = "rgb(0, 0, 255)";
export const LEVEL_ASSESSED_SPACING = 1.05;
export const LEVEL_ASSESSED_FONT_SIZE = 16;
export const BULLET_VERT_ADJUSTMENT = 0.1;
export const BULLET_MARGIN = 4.5;
export const AFTER_BULLET = BULLET_MARGIN + 0.6;
export const BULLET_RADIUS = 0.05;
export const LIST_ITEM_SPACING = 0.55;
export const BOX_HEIGHT = 1.5;
export const BOX_RADIUS = 0.2;
export const SHORT_TITLE_BOX_VERT_SPACING = 0.9;
export const LONG_TITLE_BOX_VERT_SPACING = 0.6;
export const IMAGE_LEFT_MARGIN = 0.9;
export const IMAGE_SIZE = 3;
export const SHORT_TITLE_HEADER_SPACING = 1.5;
export const LONG_TITLE_HEADER_SPACING = 1.7;
export const IMAGE_OFFSET = 2;
export const DIMENSION_SPACING = 0.2;

/**
 * Additional
 */
export const DEFAULT_MAX_CHAR_IN_LINE = 90;
