import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { toggleModal } from "../../redux/modal/modal.actions";
import { userLogout } from "../../redux/root-reducer";

import "./modal.styles.css";

/**
 * Object that contains the purposes of a modal and related data
 * for the modal
 */
const purposeModal = {
  exitWarning: {
    headerContent: "Are you sure?",
    bodyContent: "Please confirm that you want to exit the survey.",
    footerType: "Confirmation",
  },

  preventNext: {
    headerContent: "Answer all questions",
    bodyContent:
      "One or more mandatory questions have not been answered. You cannot proceed until these have been completed.",
    footerType: "Close",
  },
  save: {
    headerContent: "Saved Successfully",
    bodyContent: "Your progress has been saved successfully!",
    footerType: "Continue",
  },
  unsuccessfulsSave: {
    headerContent: "Did not save!",
    bodyContent: "There was a problem while saving, please try again!",
    footerType: "Close",
  },
};

/**
 * Displays a modal to communicate confirmations with the user in order to prevent
 * unwanted actions
 * @param {() => void} toggleModal Toggles to show/hide the modal
 * @param {boolean} show Boolean value to show the modal
 * @param {string} purpose Purpose of the modal
 * @param {() => void} userLogout Function that removes user data from the app
 */
const Modal = ({ toggleModal, show, purpose, userLogout }) => {
  /**
   * Closes modal
   */
  const handleModalClose = () => {
    toggleModal();
  };

  /**
   * Proceeds to leave and logouts user
   */
  const handleProceed = () => {
    handleModalClose();
    userLogout();
  };

  /**
   * Determine the footer content depending on purpose of modal
   */
  let footerContent = <></>;
  switch (purposeModal[purpose]["footerType"]) {
    case "Confirmation":
      footerContent = (
        <>
          <button
            className="btn btn-outline-secondary btn-sm"
            onClick={(e) => handleModalClose(e)}
          >
            Cancel <i className="fa fa-close"></i>
          </button>
          <Link to="/exit">
            <button
              className="btn btn-outline-danger btn-sm"
              onClick={(e) => handleProceed(e)}
            >
              Proceed <i className="fa fa-check" aria-hidden="true"></i>
            </button>
          </Link>
        </>
      );
      break;
    case "Close":
    case "Continue":
    default:
      footerContent = (
        <button
          className="btn btn-outline-secondary btn-sm"
          onClick={(e) => handleModalClose(e)}
        >
          {purposeModal[purpose]["footerType"]}
        </button>
      );
      break;
  }

  if (show) {
    return (
      <div className="modalBackground">
        <div className="modalContent">
          <div className="modalHeader">
            <div className="modalClose" onClick={(e) => handleModalClose(e)}>
              <i className="fa fa-close w3-small" aria-hidden="true"></i>
            </div>
            <h5 className="modalHeaderText">
              {purposeModal[purpose]["headerContent"]}
            </h5>
          </div>
          <div className="modalBody">
            <p>{purposeModal[purpose]["bodyContent"]}</p>
          </div>
          <div className="modalFooter">{footerContent}</div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const mapStateToProps = ({ modal: { show, purpose } }) => ({
  show,
  purpose,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModal: () => dispatch(toggleModal()),
  userLogout: () => dispatch(userLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
