import React from "react";

import { DIGITAL_FRAMEWORK_LINK } from "../../constants/constants.data";

import "./info.styles.css";

// Objective of the framework
const objective = (
  <>
    {" "}
    <h3>What is the digital capabilities framework? </h3>
    <p>
    Digital capabilities are the set of skills and knowledge you need to use 
    digital resources in your personal and professional life. A framework is a 
    way of classifying and understanding these capabilities. Put this together 
    and you have a better way to describe and understand the competencies required 
    for the different parts of your digital life.
      <br />
      <br />
      The Digital Capabilities Framework is Concordia University’s interpretation 
      of the Digital Competency Framework developed by the{" "} 
      <a
            href={DIGITAL_FRAMEWORK_LINK}
            //The rel "noopener noreferrer" is for security purposes when using _blank
            //https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
            rel="noopener noreferrer"
            target="_blank">
              Quebec Ministère de l’Éducation et de l’Enseignement supérieur. 
          </a>
      <br />
      <br />
    </p>
    <hr />
  </>
);

// Principles of the government framework
const frameworkPrinciples = (
  <>
    <h3>Why take the digital capabilities self-assessment? </h3>
    <p>
        Any given task with a digital component can be described by one or more 
        dimensions of the Digital Capabilities Framework. By going through this survey 
        and breaking down each dimension into attitudes and skills, you will start to 
        observe patterns in your strengths and weaknesses. Consequently, you may choose 
        to plan your digital skills growth more holistically. For example, you may want 
        to work on digital collaboration skills in general, rather than just focusing 
        on your skills in SharePoint.
      <br />
      <br />
    </p>
    <hr />
  </>
);

// Information about the different dimensions
const dimensionsInfo = (
  <>
    <h3>How to interpret the results of this assessment: </h3>
    <p>
       A self-assessment is not an exact and objective measure. Different people 
       also have different needs. A medical researcher and a social media manager 
       might both have high levels of <strong>digital proficiency</strong>, but they will use 
       different tools and prioritize different capabilities.  
      <br />
      <br />
      What the scores do explain are your own strengths and weaknesses. You may find
      out that you excel in some dimensions of digital capabilities, while there 
      are other dimensions you have not considered. You can use this information
      to seek out training, guides and workshops to strengthen your weaknesses or
      to build upon your talents. The course of action is up to you!
      <br />
      <br />
    
    </p>
    <hr />
  </>
);

// Information about data privacy
const confidentialInfo = (
  <>
    <h3>Are my results confidential? </h3>
    <p>
    Your results are totally confidential even if you choose to save your progress.
     The email address you enter is not saved and as such, Concordia University does 
     not link the survey results with individuals through email or otherwise. 
      <br />
      <br />
    
    </p>
    <hr />
  </>
);
// Information about data privacy
const resourceInfo = (
  <>
    <h3>About the recommended resources: </h3>
    <p>
    The links to the resources provided by this tool are a varied list of training 
    opportunities available to you through Concordia University. External resources
     are not included at this time. 
      <br />
      <br />
    
    </p>
    <hr />
  </>
);

/**
 * This pages displays additional information about the government
 * framework if the user decides to read more.
 * @param {Object} history Browser history from react-router
 */
const Info = ({ history }) => {
  const returnButton = (
    <button
      className="btn-lg btn-primary float-right"
      onClick={() => history.goBack()}
    >
      Return
    </button>
  );
  return (
    <div className="container info">
      {objective}
      {frameworkPrinciples}
      {dimensionsInfo}
      {confidentialInfo}
      {resourceInfo}
      {returnButton}
    </div>
  );
};

export default Info;
