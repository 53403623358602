import { SurveyDataActionTypes } from "./survey-data.types";

const INITIAL_STATE = {
  answers: null,
  identity: undefined,
  level: undefined,
  currentStep: 0,
  furthestStep: 0,
};

const surveyDataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SurveyDataActionTypes.SET_ANSWERS:
      return {
        ...state,
        answers: action.payload,
      };

    case SurveyDataActionTypes.SET_IDENTITY:
      return {
        ...state,
        identity: action.payload,
      };

    case SurveyDataActionTypes.SET_LEVEL:
      return {
        ...state,
        level: action.payload,
      };

    case SurveyDataActionTypes.SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };

    case SurveyDataActionTypes.SET_FURTHEST_STEP:
      return {
        ...state,
        furthestStep: action.payload,
      };

    default:
      return state;
  }
};

export default surveyDataReducer;
