import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import API, { setAuthToken } from "../../utils/api.utils";
import { retrieveAnswers, processAnswers } from "../../utils/answers.utils";
import { getQuestions, processQuestions } from "../../utils/questions.utils";
import { setCurrentUser } from "../../redux/user/user.actions";
import {
  setAnswers,
  setIdentity,
  setLevel,
  setFurthestStep,
  setCurrentStep,
} from "../../redux/survey-data/survey-data.actions";
import { setAllQuestions } from "../../redux/questions/questions.actions";

import "./auth.styles.css";

// Initial form state
const initialState = {
  id: "",
};

// Initial error info state
const initialErrorState = { ...initialState, login: "" };

/**
 * Page for users to enter their ids to be able to continue a survey left unfinished.
 * @param {Object} history Browser history from react-router
 * @param {(Object) => void} setCurrentUser Redux action to set current user
 * @param {(Object) => void} setAnswers Redux action to set answers
 * @param {(string) => void} setIdentity Redux action to set identity group
 * @param {(number) => void} setLevel Redux action to set level that user picked
 * @param {(number) => void} setCurrentStep Redux action to set current step of user
 * @param {(number) => void} setFurthestStep Redux action to set furthest reached step of user
 * @param {(Object) => void} setAllQuestions Redux action to set all questions of user
 */
const LoginPage = ({
  history,
  setCurrentUser,
  setAnswers,
  setIdentity,
  setLevel,
  setCurrentStep,
  setFurthestStep,
  setAllQuestions,
}) => {
  // URL parameters
  const params = useParams();
  // Login info
  const [login, setLogin] = useState(initialState);
  // Error info
  const [errors, setErrors] = useState(initialErrorState);

  useEffect(() => {
    /**
     * Checks if the browser's local storage already holds a token
     * and fetches the user's data if the token is not expired
     */
    const fetchFromLocalStorage = async () => {
      let token = localStorage.getItem("jwtToken");
      if (token) {
        let decoded = jwtDecode(token);
        if (Date.now() / 1000 < decoded.exp) {
          setAuthToken(token);
          decodeAndSetUser(token);
          await retrieveQuestionsAndAnswers();
        }
      }
    };
    /**
     * Logs the user in from a URL parameter if they have a link
     * to the survey
     * @param {string} id anonymous ID for user survey
     */
    const fetchFromUrlParam = async (id) => {
      try {
        const response = await API({
          url: "user/login",
          method: "POST",
          data: {
            id,
          },
        });
        const { token } = response.data;
        storeUserToken(token);
        decodeAndSetUser(token);
        await retrieveQuestionsAndAnswers();
      } catch (e) {
        console.error(e);
        setErrors((prevErrors) => ({
          ...prevErrors,
          ...e.response.data,
        }));
      }
    };
    // When there is an id in the URL params e.g. /login/{id}
    if ("id" in params) fetchFromUrlParam(params.id);
    else fetchFromLocalStorage();
  });

  /**
   * Binds data of the input field to state in this component
   * @param {React.ChangeEvent} event input field events
   */
  const handleChange = (event) => {
    const { name, value } = event.target;
    setErrors(initialErrorState);
    setLogin((prevLogin) => ({
      ...prevLogin,
      [name]: value,
    }));
  };

  /**
   * Validates user input
   */
  const validate = () => {
    let validated = true;
    if (!login.id) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "ID cannot be empty",
      }));
      validated = false;
    }
    return validated;
  };

  /**
   * Stores a token to the local storage of the browser and sets
   * the Authorization header for all requests. The token can be accessed
   * after the browser is closed.
   * @param {string} token JWT Bearer Token
   */
  const storeUserToken = (token) => {
    localStorage.setItem("jwtToken", token);
    setAuthToken(token);
  };

  /**
   * Decodes a JWT token and sets the current user of the application
   * @param {string} token JWT Bearer token
   */
  const decodeAndSetUser = (token) => {
    const decoded = jwtDecode(token);
    setCurrentUser(decoded);
  };

  /**
   * Logs a user in with the provided ID in the text field
   * @param {React.FormEvent} event submit event
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) return;
    try {
      const response = await API({
        url: "user/login",
        method: "POST",
        data: {
          id: login.id,
        },
      });
      const { token } = response.data;
      storeUserToken(token);
      decodeAndSetUser(token);
      await retrieveQuestionsAndAnswers();
    } catch (e) {
      console.error(e);
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...e.response.data,
      }));
    }
  };

  /**
   * Retrieves a user's survey data as well as the questions
   * and sets them to the redux state.
   */
  const retrieveQuestionsAndAnswers = async () => {
    const { answers, userInfo } = await retrieveAnswers();
    let processedAnswers = processAnswers(answers);
    const questions = processQuestions(await getQuestions(userInfo.identity));
    setAllQuestions(questions);
    setAnswers(processedAnswers);
    setFurthestStep(userInfo.furthestStep);
    setCurrentStep(userInfo.furthestStep);
    setIdentity(userInfo.identity);
    setLevel(userInfo.level);
    // If the user previously finished the survey, redirect to results
    if (userInfo.completed) history.push("/results");
    else history.push("/question");
  };

  return (
    <div className="container auth">
      <h3>Load your Survey</h3>
      <p>
        You can load a survey that you have previously saved from this screen.
        Type in the <strong>email</strong> you used to save the survey, and the
        password.
        <br />
        <br />
        <strong>Note:</strong> If you have{" "}
        <strong>previously completed the survey</strong>, you will be sent to
        your results.
      </p>
      <form noValidate onSubmit={handleSubmit}>
        <div className="form-group row">
          <label htmlFor="name" className="col-sm-2 col-form-label">
            ID:{" "}
          </label>
          <div className="col-sm-10">
            <input
              type="text"
              id="id"
              name="id"
              value={login.email}
              placeholder="Enter ID"
              onChange={handleChange}
              className={`form-control ${
                errors.email ? "text-danger is-invalid" : ""
              }`}
              required
            />
            <div className="invalid-feedback">{errors.email}</div>
          </div>
        </div>
        <div className="text-danger">{errors.login}</div>
        <div className="button-group">
          <button className="btn btn-primary" type="submit" name="login">
            Login
          </button>
        </div>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  setAnswers: (answers) => dispatch(setAnswers(answers)),
  setIdentity: (identity) => dispatch(setIdentity(identity)),
  setLevel: (level) => dispatch(setLevel(level)),
  setCurrentStep: (step) => dispatch(setCurrentStep(step)),
  setFurthestStep: (step) => dispatch(setFurthestStep(step)),
  setAllQuestions: (allQuestions) => dispatch(setAllQuestions(allQuestions)),
});

export default connect(null, mapDispatchToProps)(LoginPage);
