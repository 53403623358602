import API from "./api.utils";

/**
 * Gets the questions based on the chosen identity group
 * @param identity The identity group that was selected
 */
export const getQuestions = async (identity) => {
  try {
    const response = await API({
      url: `/questions/${identity}`,
      method: "GET",
    });
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

/**
 * Processes questions to be compatible with the Question Table component
 * @param rawQuestions Questions that come from the database
 */
export const processQuestions = (rawQuestions) => {
  let processedQuestions = {};
  for (let question of rawQuestions) {
    if (question.dimension in processedQuestions) {
      processedQuestions[question.dimension].push({
        questionId: question.questionID,
        question: question.question,
      });
    } else {
      processedQuestions[question.dimension] = [
        { questionId: question.questionID, question: question.question },
      ];
    }
  }
  return processedQuestions;
};
