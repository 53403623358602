import React from "react";
import { Switch, Route } from "react-router-dom";

import Header from "./components/header/header.component";
import Footer from "./components/footer/footer.component";
import Modal from "./components/modal/modal.component";

import LandingPage from "./pages/landing/landing.component";
import LoginPage from "./pages/auth/login.component";
import RegistrationPage from "./pages/auth/registration.component";
import QuestionPage from "./pages/question/question.component";
import Results from "./pages/results/results.component";
import ExitPage from "./pages/exit/exit.component";
import SuccessfulSavePage from "./pages/exit/save.component";
import Info from "./pages/info/info.component";
import NotFound from "./pages/not-found/not-found.component";

import "./App.css";

/**
 * This component holds the entire application.
 * It uses React router to handle different URL paths
 */
const App = () => {
  return (
    <div className="App">
      <Header />
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/question" component={QuestionPage} />
        <Route exact path="/register" component={RegistrationPage} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/login/:id" component={LoginPage} />
        <Route exact path="/results" component={Results} />
        <Route exact path="/exit" component={ExitPage} />
        <Route exact path="/successfulsave" component={SuccessfulSavePage} />
        <Route exact path="/info" component={Info} />
        <Route path="*" component={NotFound} />
      </Switch>
      <Footer />
      <Modal />
    </div>
  );
};

export default App;
