import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import API from "../../utils/api.utils";
import {
  setAnswers,
  setFurthestStep,
  setCurrentStep,
} from "../../redux/survey-data/survey-data.actions";
import { toggleModal, setPurpose } from "../../redux/modal/modal.actions";
import { setAllQuestions } from "../../redux/questions/questions.actions";
import { getQuestions, processQuestions } from "../../utils/questions.utils";
import { createAnswers } from "../../utils/answers.utils";

import { NUM_DIMENSION } from "../../constants/constants.data";
import { SLIDE_CONTENT } from "../../components/carousel/slide/slide.data";

import BeforeWeBegin from "../../components/before-we-begin/before-we-begin.component";
import ProgressBar from "../../components/progress-bar/progress-bar.component";
import QuestionTableContainer from "../../components/question-table-container/question-table-container.component";

import "./question.styles.css";

/**
 * This component takes care of the question pages of the survey.
 * There are 13 unique pages, where page 0 is the "Before we begin" page
 * which retrieves the initial data required to proceed and give the
 * respective groups their own questions. Pages 1-12 are for each digital
 * capabilities dimension.
 * @param {Object} history Browser history from react-router
 * @param {Object} answers Answers of the user
 * @param {string} identity Identity group chosen by user
 * @param {number} level Level chosen by user
 * @param {(Object) => void} setAnswers Redux action to set answers
 * @param {Object} user User info, ID for survey
 * @param {() => void} toggleModal Toggles to show/hide the modal
 * @param {(string) => void} setPurpose Sets the purpose of the modal
 * @param {(number) => void} setFurthestStep Redux action to set furthest reached step of user
 * @param {(number) => void} setCurrentStep Redux action to set current step of user
 * @param {number} currentStep The current dimension from the Redux
 * @param {number} furthestStep Furthest reached step of user from Redux
 * @param {Object} allQuestions All the questions obtained from the database
 * @param {(Object) => void} setAllQuestions Redux action to set all questions of user
 */
const QuestionPage = ({
  history,
  answers,
  identity,
  level,
  setAnswers,
  user,
  toggleModal,
  setPurpose,
  setFurthestStep,
  setCurrentStep,
  currentStep,
  furthestStep,
  allQuestions,
  setAllQuestions,
}) => {
  // If the questions on a page are answered
  const [answered, setAnswered] = useState(false);
  // If the questions are loading
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Loading is true when the data does not exist
   */
  useEffect(() => {
    if (allQuestions === null && currentStep !== 0) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [allQuestions, currentStep]);

  /**
   * When the user changes page, the scoll bar goes back to the
   * top of the page
   */
  useEffect(() => {
    window.scrollTo(0, 0);
    setFurthestStep(Math.max(furthestStep, currentStep));
  }, [currentStep, setFurthestStep, furthestStep]);

  /**
   * This function retrieves from the database the questions needed
   * depending on the user identity and the dimension they are currently at
   */
  const handleBeforeWeBeginSubmit = async () => {
    let data = await getQuestions(identity);
    let processedQuestions = processQuestions(data);
    setAllQuestions(processedQuestions);
    // Stores answers if they are available or create the inital answers
    setAnswers(answers ? answers : createAnswers(data));
  };

  /**
   * This function is called when the user wants to go back on the previous
   * page
   */
  const previous = () => {
    // Do nothing if on "Before we begin" page
    if (currentStep === 0) return;
    setCurrentStep(currentStep - 1);
  };

  /**
   * This function is called when the user is done answer the current
   * page's questions
   */
  const next = () => {
    if (!answered) {
      toggleModal();
      setPurpose("preventNext");
      return;
    }
    // Do nothing if on last page
    if (currentStep === NUM_DIMENSION) return;
    // Query for questions after the BeforeWeBegin page
    if (currentStep === 0) handleBeforeWeBeginSubmit();
    // Increase page/dimension counter
    setCurrentStep(currentStep + 1);
  };

  //This function stores the user answers
  const handleSelect = (step, id, value) => {
    let prevAnswers = answers;
    prevAnswers[step][id] = value;
    setAnswers({ ...prevAnswers });
  };

  /**
   * Submits the user answers to the server using an anonymous id
   * if the user is not logged in. Otherwise, user id will be used.
   * @param {React.FormEvent} event submit event
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    let id = user.currentUser ? user.currentUser.id : uuidv4();
    try {
      await API({
        url: "/results/submission",
        method: "POST",
        data: {
          id,
          identity,
          level,
          answers,
          furthestStep,
        },
      });
      history.push("/results");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="container page">
      {/* Return the "Before we begin" page on */}
      {currentStep === 0 ? (
        <BeforeWeBegin setAnswered={setAnswered} />
      ) : (
        /* Return dimesion question page */
        <div>
          <img
            className="dimension-header-img"
            src={`images/dimension-headers/logo_${currentStep}_text.png`}
            alt={`logo ${currentStep}`}
          />
          <ProgressBar
            percentage={((100 * furthestStep) / (NUM_DIMENSION + 1)).toFixed(0)}
          />
          <hr />
          <h5>
            <strong>{SLIDE_CONTENT[currentStep]["label"]}</strong>
          </h5>
          <ul className="dimension-descriptors">
            {SLIDE_CONTENT[currentStep]["description"].map((item, key) => (
              <li key={key}>{item}</li>
            ))}
          </ul>
          <hr />
        </div>
      )}
      <form noValidate onSubmit={handleSubmit}>
        <QuestionTableContainer
          currentStep={currentStep}
          handleSelect={handleSelect}
          answers={answers}
          setAnswered={setAnswered}
          isLoading={isLoading}
        />

        {/* Navigation buttons */}
        <div className="navigation">
          {currentStep > 1 && (
            <button
              className="btn btn-primary float-left bottom-space"
              type="button"
              onClick={previous}
            >
              Previous
            </button>
          )}
          {currentStep < NUM_DIMENSION && !isLoading && (
            <button
              className={`btn btn-primary float-right bottom-space ${
                answered ? "" : "disabled"
              }`}
              type="button"
              onClick={next}
            >
              Next
            </button>
          )}
          {currentStep === NUM_DIMENSION && !isLoading && (
            <button
              className={`btn btn-primary float-right bottom-space ${
                answered ? "" : "disabled"
              }`}
              type="submit"
            >
              Finish Survey
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = ({
  surveyData: { answers, identity, level, currentStep, furthestStep },
  user,
  questions: { allQuestions },
}) => ({
  answers,
  identity,
  level,
  user,
  furthestStep,
  currentStep,
  allQuestions,
});

const mapDispatchToProps = (dispatch) => ({
  setAnswers: (answers) => dispatch(setAnswers(answers)),
  toggleModal: () => dispatch(toggleModal()),
  setPurpose: (purpose) => dispatch(setPurpose(purpose)),
  setCurrentStep: (currentStep) => dispatch(setCurrentStep(currentStep)),
  setFurthestStep: (furthestStep) => dispatch(setFurthestStep(furthestStep)),
  setAllQuestions: (allQuestions) => dispatch(setAllQuestions(allQuestions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionPage);
