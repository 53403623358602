import React, { useState } from "react";
import { connect } from "react-redux";

import API from "../../utils/api.utils";
import { validateEmail } from "./auth.utils";
import { isEmpty } from "../../utils/answers.utils";
import { userLogout } from "../../redux/root-reducer";

import "./auth.styles.css";

// Maximum characters allowed for email
const MAX_CHAR_ALLOWED = 128;

// Initial form state
const initialState = {
  email: "",
};

// Inital error info state
const initialErrorState = {
  ...initialState,
  surveyData: "",
};

/**
 * Registration page to let users save unfinished surveys.
 * @param {Object} answers Answers of the user
 * @param {Object} history Browser history from react-router
 * @param {string} identity Identity group chosen by user
 * @param {number} level Level chosen by user
 * @param {number} furthestStep Furthest reached step of user from Redux
 * @param {() => void} userLogout Function that removes user data from the app
 */
const RegistrationPage = ({
  answers,
  history,
  identity,
  level,
  furthestStep,
  userLogout,
}) => {
  // User form info
  const [newUser, setNewUser] = useState(initialState);
  // Error info
  const [errors, setErrors] = useState(initialErrorState);

  /**
   * Handles changes for text fields
   * @param {React.ChangeEvent} event
   */
  const handleChange = (event) => {
    const { name, value } = event.target;
    setErrors(initialState);
    setNewUser((prevNewUser) => ({
      ...prevNewUser,
      [name]: value,
    }));
  };

  /**
   * Validates user info.
   */
  const validate = () => {
    let validated = true;
    setErrors(initialErrorState);
    if (!newUser.email) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email cannot be empty",
      }));
      validated = false;
    }
    if (!validateEmail(newUser.email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email must be valid",
      }));
      validated = false;
    }
    if (isEmpty(answers) || identity === undefined || level === undefined) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        surveyData: "No answers to save",
      }));
      validated = false;
    }
    return validated;
  };

  /**
   * Registers the user and saves survey progress and data.
   * @param {React.FormEvent} event submit event
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { target } = event;
    if (!validate()) return;
    try {
      target.disabled = true;
      await API({
        url: "user/register",
        method: "POST",
        data: {
          email: newUser.email,
          answers,
          identity,
          level,
          furthestStep,
        },
      });
      userLogout();
      history.push("/successfulsave");
    } catch (e) {
      console.error(e);
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...e.response.data,
      }));
    } finally {
      target.disabled = false;
    }
  };

  return (
    <div className="container auth">
      <h3>Save your unfinished Survey</h3>
      <p>
        Enter a <strong> an email</strong> for this survey and click save below.
      </p>
      <p>
        Your survey will be saved using an anonymous ID, and can be completed
        later by accessing the link sent to your email.
      </p>

      <p>
        After clicking the <strong>Save &amp; Exit</strong> button, you will be
        exited from the survey. You can login and continue from wherever you
        left off by returning to the <strong>home page</strong>.
      </p>

      <form noValidate>
        <div className="text-danger">{errors.surveyData}</div>
        <div className="form-group row">
          <label htmlFor="email" className="col-sm-2 col-form-label">
            Email*:{" "}
          </label>
          <div className="col-sm-10">
            <input
              type="email"
              id="email"
              name="email"
              value={newUser.email}
              placeholder="Enter email"
              onChange={handleChange}
              maxLength={MAX_CHAR_ALLOWED}
              className={`form-control ${
                errors.email ? "text-danger is-invalid" : ""
              }`}
              required
            />
            <div className="invalid-feedback">{errors.email}</div>
          </div>
        </div>
        <div className="button-group">
          <button
            className="btn btn-primary"
            type="submit"
            name="exit"
            onClick={handleSubmit}
          >
            Save &amp; Exit
          </button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = ({
  surveyData: { answers, identity, level, furthestStep },
}) => ({
  answers,
  identity,
  level,
  furthestStep,
});

const mapDispatchToProps = (dispatch) => ({
  userLogout: () => dispatch(userLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);
