import React from "react";
import { Link } from "react-router-dom";

import "./not-found.styles.css";

/**
 * Page shown if user enter an unsupported URL path
 */
const NotFound = () => {
  return (
    <div className="container pageNotFound">
      <div className="d-flex justify-content-center">
        <img
          className="framework-design-03"
          src="/images/framework-design-03.png"
          alt="Framework design 3"
        />
        <img
          className="framework-design-02"
          src="/images/framework-design-02.png"
          alt="Framework design 2"
        />
      </div>
      <h2>Page not found! 404</h2>
      <p>
        Whoops! Something's not quite right. We can't seem to find the page you
        are looking for. Try going back to the previous page or
        <Link to="/"> click here to go on the home page</Link>
      </p>
      <div className="d-flex justify-content-center">
        <img
          className="framework-design-01"
          src="/images/framework-design-01.png"
          alt="Framework design 1"
        />
        <img
          className="framework-design-04"
          src="/images/framework-design-04.png"
          alt="Framework design 4"
        />
      </div>
    </div>
  );
};

export default NotFound;
