import React from "react";

import "./progress-bar.styles.css";

/**
 * The fill-in part of the progress bar
 * @param {number} percentage Percentage of the progress bar
 */
const Filler = ({ percentage }) => {
  const fillerBarStyle = { width: `${percentage}%` };
  return (
    <div className="filler" style={fillerBarStyle}>
      <span className="filler-content">{percentage}%</span>
    </div>
  );
};

export default Filler;
