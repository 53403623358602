export const groupDependentText = {
  undergraduate: {
    text:
      "The Student Success Centre offers the FutureReady program to help undergraduate students develop the skills you need to successfully transition from university into the workplace.",
    link: "https://www.concordia.ca/students/success/future-ready.html",
  },
  graduate: {
    text:
      "GradProSkills offers approximately 350 workshops and events each year to help Concordia graduate students develop competencies in the professional skills that employers are seeking.",
    link: "https://www.concordia.ca/students/gradproskills.html",
  },
  faculty: {
    text:
      "Concordia’s Center for Teaching and Learning (CTL) offers a number of workshops as well assistance in using innovative technologies for pedagogical purposes.  Human Resources also has workshops and courses available to Faculty. Cspace login is required to view or register fo these courses.",
    link: "http://www.concordia.ca/offices/ctl.html",
  },
  staff: {
    text:
      "Concordia’s Human Recourses encourages employees to actively pursue training and professional development opportunities. To that end, Human Resources has developed a set of workshops and courses to allow employees and managers to enhance their digital knowledge and skills throughout their careers at the university. Cspace login is required to view these workshops.",
    link: "http://www.concordia.ca/hr/training-development.html",
  },
};

export const supportText = {
  1: {
    text:
      "The Concordia library has a number resources that you can consult by using their discovery search.  They also provide a number of workshops as well as the 23 Things for Digital Knowledge programme.",
    link: "https://library.concordia.ca/help/workshops/",
  },
  2: {
    text:
      "IITS provides IT training to faculty, staff and students. Training is delivered through instructor-lead workshops, online materials, demos and individual lessons. Workshops range from the basic to advanced levels in applications such as MS Office (Word, Excel, PowerPoint, Outlook), Dreamweaver, Photoshop, and Moodle Learning Management System.",
    link: "https://www.concordia.ca/it/services/it-training.html",
  },
  3: {
    text:
      "Concordia Continuing Education (CCE) offers extended paid non-credit courses and workshops on a number of digital topics that provide an in-depth exploration of the topic.",
    link: "https://www.concordia.ca/cce/courses.html",
  },
};
