import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  setIdentity,
  setLevel,
} from "../../redux/survey-data/survey-data.actions";

import { IDENTITIES_GROUPS, LEVELS } from "./before-we-begin.data";

import "./before-we-begin.styles.css";

/**
 * This component contains the contents of the Before We Begin page.
 * @param {string} identity Identity group chosen by user
 * @param {number} level Level chosen by user
 * @param {(string) => void} setIdentity Redux action to set identity group
 * @param {(number) => void} setLevel Redux action to set level selected
 * @param {(boolean) => void} setAnswered Function from Question component
 * to set if questions all questions are answered.
 */
const BeforeWeBegin = ({
  identity,
  level,
  setIdentity,
  setLevel,
  setAnswered,
}) => {
  /**
   * Instructions for the questions
   */
  const intructionElement = (
    <p>
      <i className="fa fa-exclamation-circle" /> Choose <strong>one</strong> of
      the following answers.
    </p>
  );

  useEffect(() => {
    /**
     * This function checks if the questions are answered on this page
     */
    const checkAnswered = () => {
      if (!!identity && !!level) {
        setAnswered(true);
        return;
      }
      setAnswered(false);
    };
    checkAnswered();
  });

  /**
   * Sets selected value for identity and level
   * @param {} event onChange event
   */
  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "identity":
        setIdentity(value);
        break;
      case "level":
        setLevel(value);
        break;
      default:
        break;
    }
  };

  return (
    <div className="container">
      <div className="text-left">
        <h2>
          <strong>Before we begin...</strong>
        </h2>
        <p>
          We would like to ask you a few questions in order to best tailor the
          self-assessment for you.
        </p>
        <div className="card">
          <h5>
            <strong>Which of the groups do you mostly identify with?</strong>
          </h5>
          {intructionElement}
          <div className="radio-buttons">
            {IDENTITIES_GROUPS.map((group) => (
              <label key={group.value}>
                <input
                  type="radio"
                  name="identity"
                  value={group.value}
                  checked={identity === group.value}
                  onChange={handleChange}
                />{" "}
                {group.text}
              </label>
            ))}
          </div>
        </div>
        <div className="card">
          <h5>
            <strong>
              How would you rate your overall{" "}
              <Link to="/info">digital proficiency level</Link>?
            </strong>
          </h5>
          {intructionElement}
          <div className="radio-buttons">
            {LEVELS.map((levelItem) => (
              <label key={levelItem.value}>
                <input
                  type="radio"
                  name="level"
                  value={levelItem.value}
                  checked={level === levelItem.value}
                  onChange={handleChange}
                />{" "}
                {levelItem.text}
              </label>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ surveyData: { identity, level } }) => ({
  identity,
  level,
});

const mapDispatchToProps = (dispatch) => ({
  setIdentity: (identity) => dispatch(setIdentity(identity)),
  setLevel: (level) => dispatch(setLevel(level)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BeforeWeBegin);
