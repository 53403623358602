import React from "react";
import { connect } from "react-redux";

import { NUM_DIMENSION } from "../../constants/constants.data";

import QuestionTable from "../question-table/question-table.component";
import WithSpinner from "../with-spinner/with-spinner.component";

/**
 * Container for the questions table components that utilizes a spinner for
 * loading
 * @param {number} currentStep The current dimension from the Redux
 * @param {Object} allQuestions All the questions obtained from the database
 * @param {Object} answers Answers of the user
 * @param {() => void} handleSelect Handler of the radio buttons
 * @param {(Object) => void} setAnswered Function to set if all questions are answered on a page
 */
const QuestionTableContainer = ({
  currentStep,
  allQuestions,
  answers,
  handleSelect,
  setAnswered,
}) => {
  /**
   * Store the question tables in an array which will render based on the current step
   */
  let questionTables = [];
  if (allQuestions) {
    for (let dimension = 1; dimension <= NUM_DIMENSION; dimension++) {
      questionTables.push(
        <QuestionTable
          key={dimension}
          step={dimension}
          currentStep={currentStep}
          answers={answers}
          handleSelect={handleSelect}
          questions={allQuestions[dimension]}
          setAnswered={setAnswered}
        />
      );
    }
  }

  return <>{questionTables}</>;
};

const mapStateToProps = ({
  surveyData: { answers, identity, level, furthestStep },
  questions: { allQuestions },
}) => ({
  answers,
  identity,
  level,
  furthestStep,
  allQuestions,
});

export default connect(
  mapStateToProps,
  null
)(WithSpinner(QuestionTableContainer));
