import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { RESPONSIVE_WIDTH } from "../../constants/constants.data";

import "./landing.styles.css";

/**
 * The landing page of the survey
 */
const LandingPage = () => {
  // CSS classes for the diagram image element
  const [diagramClass, setDiagramClass] = useState("diagram");
  // CSS classes for background shade when the image element is expanded
  const [backgroundShade, setBackgroundShade] = useState("");
  // Boolean value for responsive elements
  const [responsive, setResponsive] = useState(() => {
    /**
     * Checks if question table must be responsive based on
     * screen size when first rendered
     */
    if (window.innerWidth <= RESPONSIVE_WIDTH) {
      return true;
    } else {
      return false;
    }
  });

  /**
   * Lifecycle hook to get viewport width
   */
  useEffect(() => {
    /**
     * Callback function for event listener
     */
    const isResponsive = () => {
      if (window.innerWidth <= RESPONSIVE_WIDTH) {
        setResponsive(true);
      } else {
        setResponsive(false);
      }
    };
    window.addEventListener("resize", isResponsive);
    return () => {
      window.removeEventListener("resize", isResponsive);
    };
  }, []);

  /**
   * Swap DOM elements if the viewport is mobile.
   */
  useEffect(() => {
    const swapDOM = () => {
      let flexContainer = document.getElementById("flex");
      let imageContainer = document.getElementById("image");
      let textContainer = document.getElementById("text");

      if (responsive) {
        flexContainer.insertBefore(imageContainer, flexContainer.firstChild);
      } else {
        flexContainer.insertBefore(textContainer, imageContainer);
      }
    };

    swapDOM();
  }, [responsive]);

  /**
   * Toggles to expand/minimize the diagram image
   */
  const toggleDiagramClass = () => {
    if (diagramClass === "diagram") {
      setBackgroundShade("fullscreen-img-backgroud");
      setDiagramClass("diagram fullscreen-img");
    } else {
      setBackgroundShade("");
      setDiagramClass("diagram");
    }
  };

  return (
    <div className="container">
      {responsive ? (
        <>
          <h2>Digital Capabilities</h2>
          <h3>Self Assessment</h3>
        </>
      ) : (
        <h2>Digital Capabilities - Self Assessment</h2>
      )}
      <p>
        The <strong>digital capabilities</strong> project aims to provide
        students, faculty, and staff with a better understanding of their own
        digital capabilities’ strengths and weaknesses. To help the Concordia
        community work on these digital skills, we are providing an online
        self-assessment tool that will{" "}
        <strong>help assess digital skills</strong> in the areas outlined in the
        diagram. <Link to="/info">Read more!</Link>
      </p>

      <div className={responsive ? "" : "d-flex"} id="flex">
        <div className={responsive ? "" : "side-text"} id="text">
          <p>
            This assessment tool will help members of the community
            comprehensively gauge their current level of proficiency in order to
            be better directed toward resources to improve in the areas where
            they need it most.
          </p>
          <p>
            The result of this self-assesment is anonymous and is meant to be a
            voluntary tool for members of our community.
          </p>
          <p>
            <strong>
              This self-assessment usually takes about 15-20 minutes to complete.
            </strong>
          </p>
          <div className="survey-button-container">
            <Link to="/question">
              <button className="btn-lg btn-primary survey-button">
                Complete the self-assessment
              </button>
            </Link>
          </div>
          <p className="login-statement">
            Click here to <Link to="/login">Resume Survey</Link>
          </p>
        </div>
        <div className={responsive ? "in-img" : "side-img"} id="image">
          <div className={backgroundShade} onClick={() => toggleDiagramClass()}>
            <img
              className={diagramClass}
              src="images/framework-diagram.png"
              alt="framework-diagram"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
