import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "./user/user.reducer";
import surveyDataReducer from "./survey-data/survey-data.reducer";
import modalReducer from "./modal/modal.reducer";
import questionReducer from "./questions/questions.reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["surveyData", "questions"],
};

const appReducer = combineReducers({
  user: userReducer,
  surveyData: surveyDataReducer,
  modal: modalReducer,
  questions: questionReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export const userLogout = () => ({
  type: "LOGOUT",
});

export default persistReducer(persistConfig, rootReducer);
