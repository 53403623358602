import { Chart } from "react-chartjs-2";
import { sortBy, reverse } from "lodash";

import { getAverage } from "../../utils/analytics.utils";

import {
  NUM_DIMENSION,
  RADAR_FILL_COLOR,
  AVERAGE_FILL_COLOR,
} from "../../constants/constants.data";
import { SLIDE_CONTENT } from "../carousel/slide/slide.data";

/**
 * Retrieves and return an array of the labels
 */
export const getAllLabelsInOriginalOrder = () => {
  let labels = [];
  for (let dimension = 1; dimension <= NUM_DIMENSION; dimension++) {
    labels.push(SLIDE_CONTENT[dimension]["label"]);
  }
  return labels;
};

/**
 * Retrieves and return an array of the 2 longest labels indices such that
 * @param {Array<string>} labels array of labels
 */
export const getTwoBiggestLabels = (labels) => {
  labels = labels.map((l, idx) => ({ value: l.length, idx }));
  let sortedLabels = reverse(sortBy(labels, [({ value }) => value]));
  return [sortedLabels[0].idx, sortedLabels[1].idx];
};

/**
 * Swaps the contents of an array between two indices
 * @param {Array} arr array
 * @param {number} i first index
 * @param {number} j second index
 */
export const arraySwap = (arr, i, j) => {
  let tmp = arr[i];
  arr[i] = arr[j];
  arr[j] = tmp;
};

/**
 * Retrieves the average value of the variable group and adjusts it for
 * the swapped labels
 * @param {string} identity identity group of the user
 * @param {Array<number>} swappedIndices indices returned from getTwoBiggestLabels
 */
export const retrieveAverage = async (identity, swappedIndices) => {
  let rawIdentityAverage = (await getAverage())[identity.toUpperCase()];
  let processedAverageData = [];
  for (let dimension = 1; dimension <= NUM_DIMENSION; dimension++) {
    let middle = Math.ceil(NUM_DIMENSION / 2) + 1;
    switch (dimension) {
      case 1:
        processedAverageData.push(rawIdentityAverage[swappedIndices[0] + 1]);
        break;
      case middle:
        processedAverageData.push(rawIdentityAverage[swappedIndices[1] + 1]);
        break;
      case swappedIndices[0] + 1:
        processedAverageData.push(rawIdentityAverage["1"]);
        break;
      case swappedIndices[1] + 1:
        processedAverageData.push(rawIdentityAverage[middle]);
        break;
      default:
        processedAverageData.push(rawIdentityAverage[dimension]);
        break;
    }
  }
  return processedAverageData;
};

/**
 * Adjusts the color array for the bar chart to account for swapped indices
 * @param {Array<string>} usedLabels Array of labels
 * @param {Array<number>} swappedIndices Indices returned from getTwoBiggestLabels
 */
export const adjustBarChartColors = (usedLabels, swappedIndices) => {
  const barColorArray = [];
  for (let dimension = 0; dimension < usedLabels.length; dimension++) {
    let middle = Math.ceil(NUM_DIMENSION / 2);
    switch (dimension) {
      case 0:
        barColorArray.push(SLIDE_CONTENT[swappedIndices[0] + 1].color);
        break;
      case middle:
        barColorArray.push(SLIDE_CONTENT[swappedIndices[1] + 1].color);
        break;
      case swappedIndices[0]:
        barColorArray.push(SLIDE_CONTENT[1].color);
        break;
      case swappedIndices[1]:
        barColorArray.push(SLIDE_CONTENT[middle + 1].color);
        break;
      default:
        barColorArray.push(SLIDE_CONTENT[dimension + 1].color);
        break;
    }
  }
  return barColorArray;
};

/**
 * Choose dataset config. If there are enough completed survey
 * for user group, display average, else don't.
 * @param {Array<string>} barColorArray barColorArray Array of colors
 * @param {boolean} minimumCompletedToShow boolean value that tells
 * if there are enough surveys for showing average
 * @param {Array<number>} processedAverageData Processed average after retrieved
 * @param {Array<number>} usedData Data used for charts
 */
export const getChartDataSets = (
  barColorArray,
  minimumCompletedToShow,
  processedAverageData,
  usedData
) => {
  let radarDataSet = [
    {
      label: "Digital Capabilities Score",
      data: usedData,
      backgroundColor: RADAR_FILL_COLOR,
      order: 1,
    },
  ];
  let barDataSet = [
    {
      label: "Digital Capabilities Score",
      data: usedData,
      backgroundColor: barColorArray,
      order: 1,
    },
  ];
  if (minimumCompletedToShow) {
    radarDataSet.push({
      label: "Average",
      data: processedAverageData,
      hidden: true,
      backgroundColor: AVERAGE_FILL_COLOR,
      order: 2,
    });
    barDataSet.push({
      label: "Average",
      data: processedAverageData,
      hidden: true,
      backgroundColor: AVERAGE_FILL_COLOR,
      order: 2,
    });
  }
  return { radarDataSet, barDataSet };
};

/**
 * Sets up options for the radar and bar charts
 * @param {Array<number>} usedLabels Data used for charts
 * @param {Array<number>} swappedIndices Indices returned from getTwoBiggestLabels
 * @param {(number, boolean?) => void} changeSlide function to change slide
 * @param {boolean} minimumCompletedToShow boolean value that tells
 * if there are enough surveys for showing average
 */
export const getChartOptions = (
  usedLabels,
  swappedIndices,
  changeSlide,
  minimumCompletedToShow
) => {
  /**
   * Redirects user to correspond slide when user clicks on one of the bars from the bar graph
   */
  const handleBarClick = (event, object) => {
    //Only do something if user clicked on a bar
    if (object.length > 0) {
      const clickedLabel = object[0]._view.label;
      for (let i = 0; i < usedLabels.length; i++) {
        if (usedLabels[i] === clickedLabel) {
          //Accomodate for the swap made
          let middle = Math.ceil(NUM_DIMENSION / 2);
          switch (i) {
            case 0:
              changeSlide(swappedIndices[0] + 1, true);
              break;
            case middle:
              changeSlide(swappedIndices[1] + 1, true);
              break;
            case swappedIndices[0]:
              changeSlide(1, true);
              break;
            case swappedIndices[1]:
              changeSlide(middle + 1, true);
              break;
            default:
              changeSlide(i + 1, true);
              break;
          }
          return;
        }
      }
    }
  };
  const radarOption = {
    legend: { display: minimumCompletedToShow },
    scale: {
      pointLabels: {
        fontSize: 14,
        fontColor: "rgb(0,0,0)",
      },
      ticks: {
        beginAtZero: true,
        max: 100,
      },
    },
  };

  const barOption = {
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          ticks: {
            autoSkip: false,
            maxRotation: 80,
            minRotation: 80,
            fontSize: 8,
            fontFamily: "Verdana, Geneva, sans-serif",
            fontStyle: "bold",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            max: 100,
            fontSize: 8,
            fontFamily: "Verdana, Geneva, sans-serif",
          },
        },
      ],
    },
    legend: {
      display: minimumCompletedToShow,
      labels: {
        fontSize: 10,
        fontFamily: "Verdana, Geneva, sans-serif",
      },
    },
    onClick: (event, object) => handleBarClick(event, object),
  };

  return { radarOption, barOption };
};

/**
 * This function handles events that are happening on the chart or bar graph.
 * Source: https://stackoverflow.com/questions/50470784/make-chart-js-radar-labels-clickable
 * @param {Event} e mousemove or onclick event
 * @param {Chart} radarChart reference to radar chart on page
 * @param {Array<number>} swappedIndices indices of 2 longest labels
 * @param {Function} changeSlide function to change slide
 */
export const radarEvent = (e, radarChart, swappedIndices, changeSlide) => {
  let helpers = Chart.helpers;
  let scale = radarChart.scale;
  let opts = scale.options;
  let tickOpts = opts.ticks;
  let labelPosition = [];

  // Position of click relative to canvas.
  let mouseX = e.offsetX;
  let mouseY = e.offsetY;

  let labelPadding = 5; // number pixels to expand label bounding box by

  // get the label render position
  // calcs taken from drawPointLabels() in scale.radialLinear.js
  let tickBackdropHeight =
    tickOpts.display && opts.display
      ? helpers.valueOrDefault(
          tickOpts.fontSize,
          Chart.defaults.global.defaultFontSize
        ) + 5
      : 0;
  let outerDistance = scale.getDistanceFromCenterForValue(
    opts.ticks.reverse ? scale.min : scale.max
  );

  for (let i = 0; i < scale.pointLabels.length; i++) {
    // Extra spacing for top value due to axis labels
    let extra = i === 0 ? tickBackdropHeight / 2 : 0;
    let pointLabelPosition = scale.getPointPosition(
      i,
      outerDistance + extra + 5
    );

    // get label size info.
    let plSize = scale._pointLabelSizes[i];

    // get label textAlign info
    let angleRadians = scale.getIndexAngle(i);
    let angle = helpers.toDegrees(angleRadians);
    let textAlign = "right";
    if (angle === 0 || angle === 180) {
      textAlign = "center";
    } else if (angle < 180) {
      textAlign = "left";
    }

    // get label vertical offset info
    // also from drawPointLabels() calcs
    let verticalTextOffset = 0;
    if (angle === 90 || angle === 270) {
      verticalTextOffset = plSize.h / 2;
    } else if (angle > 270 || angle < 90) {
      verticalTextOffset = plSize.h;
    }

    // Calculate bounding box based on textAlign
    let labelTop = pointLabelPosition.y - verticalTextOffset - labelPadding;
    let labelHeight = 2 * labelPadding + plSize.h;
    let labelBottom = labelTop + labelHeight;

    let labelWidth = plSize.w + 2 * labelPadding;
    let labelLeft;
    switch (textAlign) {
      case "center":
        labelLeft = pointLabelPosition.x - labelWidth / 2;
        break;
      case "left":
        labelLeft = pointLabelPosition.x - labelPadding;

        break;
      case "right":
        labelLeft = pointLabelPosition.x - labelWidth + labelPadding;
        break;
      default:
        break;
    }
    let labelRight = labelLeft + labelWidth;

    if (e.type === "mousemove") {
      labelPosition.push({
        labelRight: labelRight,
        labelLeft: labelLeft,
        labelBottom: labelBottom,
        labelTop: labelTop,
      });
    } else if (e.type === "click") {
      // compare to the current click
      if (
        mouseX >= labelLeft &&
        mouseX <= labelRight &&
        mouseY <= labelBottom &&
        mouseY >= labelTop
      ) {
        //Accomodate for the swap made
        let middle = Math.ceil(NUM_DIMENSION / 2);
        switch (i) {
          case 0:
            changeSlide(swappedIndices[0] + 1, true);
            break;
          case middle:
            changeSlide(swappedIndices[1] + 1, true);
            break;
          case swappedIndices[0]:
            changeSlide(1, true);
            break;
          case swappedIndices[1]:
            changeSlide(middle + 1, true);
            break;
          default:
            changeSlide(i + 1);
            break;
        }
        break;
      }
    }
  }
  if (e.type === "mousemove") {
    //Only show pointer cursor when hovering the labels
    for (let i = 0; i < labelPosition.length; i++) {
      if (
        mouseX >= labelPosition[i].labelLeft &&
        mouseX <= labelPosition[i].labelRight &&
        mouseY <= labelPosition[i].labelBottom &&
        mouseY >= labelPosition[i].labelTop
      ) {
        e.target.style.cursor = "pointer";
        break;
      } else {
        e.target.style.cursor = "default";
      }
    }
  }
};
