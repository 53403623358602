import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Chart, Radar } from "react-chartjs-2";
import { debounce } from "lodash";

import { getNumCompleted } from "../../utils/analytics.utils";
import {
  radarEvent,
  arraySwap,
  getTwoBiggestLabels,
  getAllLabelsInOriginalOrder,
  retrieveAverage,
  adjustBarChartColors,
  getChartDataSets,
  getChartOptions,
} from "./carousel-chart.utils";

import {
  MIN_COMPLETED_SURVEYS_IN_GROUP,
  NUM_DIMENSION,
} from "../../constants/constants.data";

import "./carousel-chart.styles.css";

/**
 * Documentation on using chartJS
 * https://www.chartjs.org/docs/latest/
 * https://www.npmjs.com/package/react-chartjs-2
 */

/**
 * Display the user results formatted into either a bar or radar graph
 * @param {Array<number>} formatData Array of formatted score data
 * @param {boolean} print A boolean used to render the hidden radar chart for PDF purposes only
 * @param {(number, boolean?) => void} changeSlide Function that changes the displayed slide
 * @param {string} identity User identity which was selected on Before we Begin page
 */
const CarouselChart = ({
  formatData,
  print = false,
  changeSlide,
  identity,
}) => {
  //Store modified order data for the longest labels to show on top/bottom of radar chart
  const [usedData, setUsedData] = useState([]);
  const [usedLabels, setUsedLabels] = useState(null);
  // Keeps track of the swapped indices
  // Note: The longest is at index 0, second longest index 1
  const [swappedIndices, setSwappedIndices] = useState(null);
  // Datasets for the charts
  const [radarDataset, setRadarDataset] = useState([]);
  const [barDataset, setBarDataset] = useState([]);
  // Processed average data from database
  const [processedAverageData, setProcessedAverageData] = useState(null);
  // Determines if the average is to be shown
  const [minimumCompletedToShow, setMinimumCompletedToShow] = useState(null);
  // Context variable for ChartJS
  const [radarCtx, setRadarCtx] = useState(null);
  const [barCtx, setBarCtx] = useState(null);
  // Charts
  const [radarChart, setRadarChart] = useState(null);
  const [barChart, setBarChart] = useState(null);

  /**
   * Set context for ChartJS
   */
  useEffect(() => {
    setRadarCtx(document.getElementById("radar-graph").getContext("2d"));
    setBarCtx(document.getElementById("bar-graph").getContext("2d"));

    /**
     * Retrieve and store a boolean of if there are enough completed surveys
     * for user group
     */
    const retrieveNumCompleted = async () => {
      setMinimumCompletedToShow(
        (await getNumCompleted())[identity.toUpperCase()] >=
          MIN_COMPLETED_SURVEYS_IN_GROUP
      );
    };
    retrieveNumCompleted();
  }, [setRadarCtx, setBarCtx, setMinimumCompletedToShow, identity]);

  /**
   * Retrieve and modify the order the data/labels are stored such that
   * the longest labels to show on top/bottom of radar chart (Top: index 0, Bottom: middle index)
   */
  useEffect(() => {
    let modData = [...formatData];
    let modLabels = getAllLabelsInOriginalOrder();
    let middle = Math.floor(modLabels.length / 2);
    // Get the indices of the largest labels
    const [longestDimensionIdx1, longestDimensionIdx2] = getTwoBiggestLabels(
      modLabels
    );
    setSwappedIndices([longestDimensionIdx1, longestDimensionIdx2]);
    // Swap the top
    arraySwap(modData, 0, longestDimensionIdx1);
    arraySwap(modLabels, 0, longestDimensionIdx1);
    // Swap the bottom which is half way through
    arraySwap(modData, middle, longestDimensionIdx2);
    arraySwap(modLabels, middle, longestDimensionIdx2);

    setUsedData(modData);
    setUsedLabels(modLabels);
  }, [formatData]);

  /**
   * Apply and format charts' data
   */
  useEffect(() => {
    if (
      usedData &&
      usedLabels &&
      usedData.length > 0 &&
      usedLabels.length > 0 &&
      swappedIndices &&
      identity &&
      minimumCompletedToShow !== null
    ) {
      if (processedAverageData) {
        const barColorArray = adjustBarChartColors(usedLabels, swappedIndices);
        const { radarDataSet, barDataSet } = getChartDataSets(
          barColorArray,
          minimumCompletedToShow,
          processedAverageData,
          usedData
        );
        setRadarDataset(radarDataSet);
        setBarDataset(barDataSet);
      } else {
        retrieveAverage(identity, swappedIndices).then((processed) =>
          setProcessedAverageData(processed)
        );
      }
    }
  }, [
    processedAverageData,
    usedData,
    usedLabels,
    swappedIndices,
    minimumCompletedToShow,
    identity,
  ]);

  /**
   * Create charts once all data was retrieve and set
   */
  useEffect(() => {
    if (
      radarCtx === null ||
      barCtx === null ||
      usedLabels === null ||
      radarDataset === [] ||
      barDataset === [] ||
      swappedIndices === null ||
      !changeSlide ||
      minimumCompletedToShow === null
    )
      return;

    const { radarOption, barOption } = getChartOptions(
      usedLabels,
      swappedIndices,
      changeSlide,
      minimumCompletedToShow
    );
    //Create radar chart
    setRadarChart(
      new Chart(radarCtx, {
        type: "radar",
        data: { labels: usedLabels, datasets: radarDataset },
        options: radarOption,
      })
    );
    //Create bar graph
    setBarChart(
      new Chart(barCtx, {
        type: "bar",
        data: { labels: usedLabels, datasets: barDataset },
        options: barOption,
      })
    );
  }, [
    changeSlide,
    radarCtx,
    barCtx,
    usedLabels,
    radarDataset,
    barDataset,
    swappedIndices,
    minimumCompletedToShow,
  ]);

  /**
   * Makes radar graph more interactive, beyond what ChartJS supports
   */
  useEffect(() => {
    if (radarChart && barChart) {
      document.getElementById("radar-graph").onclick = (e) =>
        radarEvent(e, radarChart, swappedIndices, changeSlide);
      document.getElementById("radar-graph").onmousemove = (e) =>
        radarEvent(e, radarChart, swappedIndices, changeSlide);
    }
  }, [radarChart, changeSlide, swappedIndices, barChart]);

  /**
   * Lifecycle hook to redraw charts when window resizes
   */
  useEffect(() => {
    /**
     * Callback function for event listener
     */
    const redrawChartOnResize = () => {
      if (radarChart && barChart) {
        radarChart.resize();
        radarChart.render();
        barChart.resize();
        barChart.render();
      }
    };
    window.addEventListener(
      "resize",
      debounce(() => redrawChartOnResize(), 500)
    );
    return () => {
      window.removeEventListener(
        "resize",
        debounce(() => redrawChartOnResize(), 500)
      );
    };
  }, [radarChart, barChart]);

  if (!print) {
    return (
      <div className="slide-chart">
        <p className="chart-text">
          Here is an <strong>INTERACTIVE</strong> chart representing your
          proficiency levels for all {NUM_DIMENSION} dimensions. Click on the
          arrows for analysis.
        </p>

        <div className="bar-graph">
          <canvas id="bar-graph"></canvas>
        </div>

        <div className="radar-graph">
          <canvas id="radar-graph"></canvas>
        </div>
      </div>
    );
  } else {
    //Radar chart option for PDF extraction
    const radarOptionPrint = {
      scale: {
        pointLabels: {
          fontSize: 14,
          fontColor: "rgb(0,0,0)",
          fontFamily: "Verdana, Geneva, sans-serif",
        },
        ticks: {
          beginAtZero: true,
          max: 100,
        },
      },
      legend: {
        display: false,
        labels: {
          fontSize: 15,
          fontColor: "rgb(0,0,0)",
          fontFamily: "Verdana, Geneva, sans-serif",
        },
      },
    };
    return (
      <div className="hidden-and-only-used-for-printing-purposes">
        <Radar
          data={{ labels: usedLabels, datasets: radarDataset }}
          options={radarOptionPrint}
          id="radar-print"
        />
      </div>
    );
  }
};

const mapStateToProps = ({ surveyData: { identity } }) => ({
  identity,
});

export default connect(mapStateToProps, null)(CarouselChart);
