import React from "react";

import "../exit/exit.styles.css";

/**
 * Page for exiting the survey
 */
const ExitPage = () => {
  return (
    <div className="container">
      <h2 className="exit">You have successfully exited this survey!</h2>
      <p className="exit">You may now close the browser safely.</p>
    </div>
  );
};

export default ExitPage;
