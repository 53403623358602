import React from "react";

import Filler from "./filler.component.jsx";

import "./progress-bar.styles.css";

/**
 * Container of the progress bar, holds the filler
 * @param {number} percentage Percentage of the progress bar
 */
const ProgressBar = ({ percentage }) => {
  return (
    <div className="progress-bar">
      <Filler percentage={percentage} />
    </div>
  );
};

export default ProgressBar;
