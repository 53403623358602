import { QuestionActionTypes } from "./questions.types";

const INITIAL_STATE = {
  allQuestions: null,
};

const questionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case QuestionActionTypes.SET_ALL_QUESTIONS:
      return {
        ...state,
        allQuestions: action.payload,
      };

    default:
      return state;
  }
};

export default questionReducer;
