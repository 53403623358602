import React, { useState, useEffect } from "react";

import { SLIDE_CONTENT } from "./slide.data";

import CarouselChart from "../../chart/carousel-chart.component";

import "./slide.styles.css";

/**
 * Displays the content of a slide
 * @param {number} slideIndex Index of the current slide
 * @param {Array<Resources>} resources Array of resources for the user
 * @param {Array<number>} formatData Array of formatted score data
 * @param {Array<UserResult>} userResults Processed array of user results
 * @param {Array<UserResult>} changeSlide Function that changes the displayed slide
 */
const Slides = ({
  slideIndex,
  resources,
  formatData,
  userResults,
  changeSlide,
}) => {
  const [slideContent, setSlideContent] = useState({});

  /**
   * Change slide resources content when the user changes the slide
   */
  useEffect(() => {
    const content = [];

    for (let row of resources) {
      if (row["dimension"] === slideIndex) {
        content.push(row);
      }
    }
    setSlideContent(content);
  }, [slideIndex, resources]);

  return (
    <div className="container-slides">
      <div className="slide">
        {slideIndex === 0 ? (
          <CarouselChart formatData={formatData} changeSlide={changeSlide} />
        ) : (
          <>
            <h5
              className="slide-header"
              style={{
                backgroundColor: `${SLIDE_CONTENT[slideIndex].color}`,
              }}
            >
              {SLIDE_CONTENT[slideIndex]["header"]}
            </h5>
            <br />
            <h5>
              Level Assessed in Dimension:{" "}
              <strong>{userResults[slideIndex - 1].level} </strong>
              <div className="slide-image">
                <img
                  alt={SLIDE_CONTENT[slideIndex]["alt"]}
                  src={SLIDE_CONTENT[slideIndex]["image"]}
                />
              </div>
            </h5>
            <br />
            <p className="slide-text">
              This dimension covers your ability to:{" "}
            </p>
            <ul>
              {SLIDE_CONTENT[slideIndex]["description"].map((item, key) => (
                <li key={key}>{item}</li>
              ))}
            </ul>
            <p className="slide-text">
              You might be interested in the following resources:{" "}
            </p>
            <ul>
              {slideContent.map((resource, key) => (
                <li key={key}>
                  <a
                    href={resource["link"]}
                    //The rel "noopener noreferrer" is for security purposes when using _blank
                    //https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {resource["resource"]}
                  </a>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default Slides;
