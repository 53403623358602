import { ModalActionTypes } from "./modal.types";

const INITIAL_STATE = { show: false, purpose: "exitWarning" };

const modalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ModalActionTypes.TOGGLE_MODAL:
      return {
        ...state,
        show: !state.show,
      };
    case ModalActionTypes.SET_PURPOSE:
      return {
        ...state,
        purpose: action.payload,
      };
    default:
      return state;
  }
};

export default modalReducer;
