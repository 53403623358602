import React, { useState, useEffect } from "react";

import { RESPONSIVE_WIDTH } from "../../constants/constants.data";

import "./question-table.styles.css";
import "./question-table-mobile.styles.css";

/**
 * This components contains the contents of the table for
 * the questions.
 * @param {number} step Dimension of a specific question table component
 * @param {number} currentStep The current dimension from the Redux
 * @param {Object} answers Answers of the user
 * @param {() => void} handleSelect Handler of the radio buttons
 * @param {Array<Object>} questions Array of questions
 * @param {(Object) => void} setAnswered Function to set if all questions are answered on a page
 */
const QuestionTable = ({
  step,
  currentStep,
  answers,
  handleSelect,
  questions,
  setAnswered,
}) => {
  useEffect(() => {
    const checkAnswered = () => {
      for (const { questionId } of questions) {
        if (answers[step][questionId] === null) {
          setAnswered(false);
          return;
        }
        setAnswered(true);
      }
    };
    if (questions && step === currentStep) checkAnswered();
  });

  const [responsive, setResponsive] = useState(() => {
    /**
     * Checks if question table must be responsive based on
     * screen size when first rendered
     */
    if (window.innerWidth <= RESPONSIVE_WIDTH) {
      return true;
    } else {
      return false;
    }
  });

  /**
   * Lifecycle hook to get viewport width
   */
  useEffect(() => {
    /**
     * Callback function for event listener
     */
    const isResponsive = () => {
      if (window.innerWidth <= RESPONSIVE_WIDTH) {
        setResponsive(true);
      } else {
        setResponsive(false);
      }
    };
    window.addEventListener("resize", isResponsive);
    return () => {
      window.removeEventListener("resize", isResponsive);
    };
  }, []);

  /**
   * Do nothing if table step does not correspond to current step
   * Prevents showing more than a table
   */
  if (currentStep !== step) return null;

  // Options for the answers
  const options = {
    NEVER: 1,
    NOT_LIKELY: 2,
    SOMETIMES: 3,
    LIKELY: 4,
    ALWAYS: 5,
  };

  // Labels of the answers
  const labels = ["Never", "Not likely", "Sometimes", "Likely", "Always"];

  /**
   * If the quesitons were retrieved and stored succesfully, display table
   */
  if (responsive) {
    return (
      <table className="responsive">
        {questions.map(({ questionId, question }) => (
          <tbody key={questionId}>
            <tr className="responsive-tr">
              <th className="responsive-th">{question}</th>
            </tr>

            {Object.entries(options).map(([option, value]) => (
              <tr key={option} className="responsive-tr">
                <td onClick={() => handleSelect(step, questionId, value)}>
                  <input
                    type="radio"
                    className="responsive-input"
                    value={value}
                    name={questionId}
                    checked={answers[step][questionId] === value}
                    onChange={() => handleSelect(step, questionId, value)}
                  />
                  {" " + labels[value - 1]}
                </td>
              </tr>
            ))}
          </tbody>
        ))}
      </table>
    );
  } else
    return (
      <table>
        <thead>
          <tr>
            <th scope="col"></th>
            {labels.map((label) => {
              return (
                <th key={label} scope="col" className="table-headers">
                  {label}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {questions.map(({ questionId, question }) => (
            <tr key={questionId}>
              <th scope="row" className="question-block">
                {question}
              </th>
              {Object.entries(options).map(([option, value]) => (
                <td
                  key={option}
                  onClick={() => handleSelect(step, questionId, value)}
                >
                  <input
                    type="radio"
                    value={value}
                    name={questionId}
                    checked={answers[step][questionId] === value}
                    onChange={() => handleSelect(step, questionId, value)}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
};

export default QuestionTable;
