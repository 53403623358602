import axios from "axios";
// Axios instance of the API
const apiInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
  responseType: "json",
});

/**
 * Sets the header for authorized requests
 * @param {string} token JWT Bearer token
 */
export const setAuthToken = (token) => {
  if (token) {
    apiInstance.defaults.headers.common["Authorization"] = token;
  } else {
    delete apiInstance.defaults.headers.common["Authorization"];
  }
};

export default apiInstance;
