import React from "react";

import "../exit/exit.styles.css";

/**
 * Page to notify of a successful save
 */
const SuccessfulSavePage = () => {
  return (
    <div className="container">
      <h2 className="exit">Sucessfully saved your survey!</h2>
      <p className="exit">You may now close the browser safely.</p>
    </div>
  );
};

export default SuccessfulSavePage;
