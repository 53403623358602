import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import API from "../../utils/api.utils";

import { NUM_DIMENSION } from "../../constants/constants.data";

import ResultContent from "../../components/result-content/result-content.component";

import "./results.style.css";

/**
 * This function displays the content of the results page
 * @param {string} identity Identity group chosen by user
 * @param {Object} answers Answers of the user for the survey
 */
const ResultsPage = ({ identity, answers }) => {
  // Array of resources for the user
  const [resources, setResources] = useState(null);
  // Formatted score data for Chart purposes
  const [formatData, setFormatData] = useState(null);
  // Processed array of user results
  const [userResults, setUserResults] = useState(null);
  // Boolean value to see if content is being fetched
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    /**
     * On page render, fetch all the resources corresponding to the user identity
     */
    const getResources = async () => {
      try {
        const response = await API({
          url: `/resources/${identity}`,
          method: "GET",
        });
        setResources(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    /**
     * Call calculate user results
     */
    const calculateResults = async () => {
      try {
        const scoreData = await API({
          url: `/calculate/logic`,
          method: "POST",
          data: {
            answers,
          },
        });
        setUserResults(scoreData.data);
      } catch (err) {
        console.log(err);
      }
    };
    if (identity) {
      getResources();
      calculateResults();
    }
  }, [identity, answers]);

  useEffect(() => {
    /**
     * Format score data to be compatible with ChartJs
     */
    const formatScores = () => {
      let tempScore = [];
      userResults.forEach(({ score }) => {
        tempScore.push(score.toFixed(2));
      });
      setFormatData(tempScore);
    };
    if (userResults) formatScores();
  }, [userResults]);

  /**
   * Used for loading the results content
   */
  useEffect(() => {
    if (answers && resources && userResults && formatData) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [resources, userResults, answers, formatData]);

  return (
    <div className="container">
      <h2>Here are your results of your assessment</h2>
      <p>
        Below you will find your proficiency level for each of the{" "}
        {NUM_DIMENSION} dimensions of the digital capabilities framework. Use
        these results to help guide you towards developing your skills in
        accordance to your needs and interests.
      </p>
      <ResultContent
        resources={resources}
        formatData={formatData}
        userResults={userResults}
        isLoading={isLoading}
      />
    </div>
  );
};

const mapStateToProps = ({ surveyData: { identity, answers } }) => ({
  identity,
  answers,
});

export default connect(mapStateToProps, null)(ResultsPage);
