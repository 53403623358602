import React from "react";

import NavBar from "./navbar/nav-menu-item.component";

import {
  CONCORDIA_LOGO_SRC,
  CONCORDIA_LOGO_ALT,
} from "../../constants/constants.data";

import "./header.styles.css";

/**
 * Displays webpage header
 */
const Header = () => {
  return (
    <nav className="navbar fixed-top navbar-expand-lg header">
      <img
        className="concordia-logo"
        src={CONCORDIA_LOGO_SRC}
        alt={CONCORDIA_LOGO_ALT}
      />
      <NavBar />
    </nav>
  );
};

export default Header;
