const imageDirectory = "images/dimensions-logos/";

// The content that is independent of user identity and user level
const SLIDE_CONTENT = [
  // Chart slide
  {
    slideNumber: 1,
  },
  // Dimension slides content
  {
    slideNumber: 2,
    header: "Excersising Ethical Citizenship in the Digital Age",
    label: "Ethical citizen",
    color: "rgb(0, 184, 224)",
    description: [
      "Act in an ethical way using digital tools.",
      "Assess the impact of the digital on your well-being.",
      "Understand digital related issues.",
      "Conduct an ethical reflection on the use of the digital and its impact on society.",
    ],
    image: imageDirectory + "Logo_01.png",
    alt: "Excersising Ethical Citizenship in the Digital Age Logo",
  },

  {
    slideNumber: 3,
    header: "Developing and Mobilizing Technological Skills",
    label: "Technological skills",
    color: "rgb(0, 48, 138)",
    description: [
      "Understand the impact of articficial intelligence.",
      "Understand how emerging digital phenomena influence society.",
      "Maintain your digital skills.",
      "Understand the basic principles of programming.",
      "Adopt secure behaviors when using digital technologies.",
      "Use digital tools to accomplish tasks.",
      "Understand the basic functioning of your everyday devices.",
      "Troubleshoot basic technical problems.",
    ],
    image: imageDirectory + "Logo_02.png",
    alt: "Developing and Mobilizing Technological Skills Logo",
  },
  {
    slideNumber: 4,
    header: "Harnessing the Potential of Digital Resources for Learning",
    label: "Digital for learning",
    color: "rgb(252, 157, 13)",
    description: [
      "Develop your skills.",
      "Identify learning resources.",
      "Fuel your curiosity and openness.",
    ],
    image: imageDirectory + "Logo_03.png",
    alt: "Harnessing the Potential of Digital Resources for Learning Logo",
  },
  {
    slideNumber: 5,
    header: "Developing and Mobilizing Information Literacy",
    label: "Information literacy",
    color: "rgb(232, 97, 0)",
    description: [
      "Select and use information.",
      "Use different information search strategies.",
      "Mobilize a variety of information resources, including experts.",
      "Evaluate the infomation you gather.",
      "Organize infomration for analysis.",
      "Recognize that information is always produced in a context.",
    ],
    image: imageDirectory + "Logo_04.png",
    alt: "Developing and Mobilizing Information Literacy Logo",
  },
  {
    slideNumber: 6,
    header: "Collaborating Via Digital Technology",
    label: "Collaboration",
    color: "rgb(0, 156, 26)",
    description: [
      "Identify opportunities for collaboration.",
      "Select and use collaboration tools.",
      "Collaborate respectfully and efficiently using digital tools.",
      "Contribute to online communities.",
      "Co-create with others using digital tools.",
    ],
    image: imageDirectory + "Logo_05.png",
    alt: "Collaborating Via Digital Technology Logo",
  },
  {
    slideNumber: 7,
    header: "Communicating Via Digital Technology",
    label: "Communication",
    color: "rgb(92, 153, 49)",
    description: [
      "Identify and apply rules and conventions to your digital communications.",
      "Select and use communication tools.",
      "Apply various communication strategies.",
      "Understand the confidentiality of different exchanges.",
    ],
    image: imageDirectory + "Logo_06.png",
    alt: "Communicating Via Digital Technology Logo",
  },
  {
    slideNumber: 8,
    header: "Producing Content Via Digital Technology",
    label: "Producing Content",
    color: "rgb(53, 199, 252)",
    description: [
      "Produce content with digital tools.",
      "Select and use the appropriate tools.",
      "Identify and use different ways of representing data.",
      "Use other's content to inspire your own while respecting ownership.",
    ],
    image: imageDirectory + "Logo_07.png",
    alt: "Producing Content Via Digital Technology Logo",
  },
  {
    slideNumber: 9,
    header: "Using Digital Tools to Foster Inclusion and Address Diverse Needs",
    label: "Inclusion and diverse needs",
    color: "rgb(20, 81, 186)",
    description: [
      "Understand that needs are diverse.",
      "Select and use the digital tools that meet your own needs.",
      "Understand that certain constraints can affect the suitability of a digital tool or resource.",
    ],
    image: imageDirectory + "Logo_08.png",
    alt:
      "Using Digital Tools to Foster Inclusion and Address Diverse Needs Logo",
  },
  {
    slideNumber: 10,
    header:
      "Mobilizing Digital Technology For Personal And Professional Empowerment",
    label: "Personal development",
    color: "rgb(111, 60, 158)",
    description: [
      "Use digital tools to develop or maintain your competencies",
      "Understand how digital tools can give you more autonomy.",
      "Use digital tools and competencies for professional integration.",
      "Adopt a practice of life long learning.",
    ],
    image: imageDirectory + "Logo_09.png",
    alt:
      "Mobilizing Digital Technology For Personal And Professional Empowerment Logo",
  },
  {
    slideNumber: 11,
    header: "Solving Diverse Problems Via Digital Technology",
    label: "Problem solving",
    color: "rgb(184, 104, 196)",
    description: [
      "Use digital tools to accurately represent a problem.",
      "Find collaborative solutions.",
      "Creatively resolve a problem.",
      "Assess your own problem solving abilities.",
    ],
    image: imageDirectory + "Logo_10.png",
    alt: "Solving Diverse Problems Via Digital Technology Logo",
  },
  {
    slideNumber: 12,
    header:
      "Developing Critical Thinking With Regard to the Use of Digital Technology",
    label: "Critical thinking",
    color: "rgb(143, 23, 85)",
    description: [
      "Critically evaluate digital content.",
      "Identify criteria to compare and evaluate digital content.",
      "Critically assess your own use of digital technologies.",
      "Identify the larger issues involved in digital technologies.",
    ],
    image: imageDirectory + "Logo_11.png",
    alt:
      "Developing Critical Thinking With Regard to the Use of Digital Technology Logo",
  },
  {
    slideNumber: 13,
    header:
      "Adopting an Innovative and Creative Approach to the Use of Digital Technology",
    label: "Innovation and creativity",
    color: "rgb(214, 9, 9)",
    description: [
      "Use digital technologies to innovate and be creative.",
      "Use innovative approach.",
      "Express your creativity.",
      "Be open to innovative and creative projects from others.",
    ],
    image: imageDirectory + "Logo_12.png",
    alt:
      "Adopting an Innovative and Creative Approach to the Use of Digital Technology Logo",
  },
];

export { SLIDE_CONTENT };
