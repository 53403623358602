import React from "react";

import { DIGITAL_FRAMEWORK_LINK } from "../../constants/constants.data";

import "./footer.styles.css";

/**
 * Displays webpage footer
 */
const Footer = () => (
  <footer className="do-not-print">
    <strong>Image reference: </strong>
    Ministère de l’Éducation et de l’Enseignement supérieur (2019). Digital
    Competency Framework.
    <br />
    <strong>Retrieved from: </strong>
    <a
      href="http://www.education.gouv.qc.ca/fileadmin/site_web/documents/ministere/Cadre-reference-competence-num-AN.pdf"
      //The rel "noopener noreferrer" is for security purposes when using _blank
      //https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
      rel="noopener noreferrer"
      target="_blank"
    >
      {DIGITAL_FRAMEWORK_LINK}
    </a>
  </footer>
);

export default Footer;
