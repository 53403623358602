import API from "./api.utils";

/**
 * Retrieves the average of each dimension according to the group identity
 */
export const getAverage = async () => {
  try {
    const response = await API({
      url: `/analytics/average`,
      method: "GET",
    });
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

/**
 * Retrieves the number of completed surveys for all group identities
 */
export const getNumCompleted = async () => {
  try {
    const response = await API({
      url: `/analytics/completed`,
      method: "GET",
    });
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
