import API from "./api.utils";

/**
 * Creates the data structure to store answers
 * Format: { [dimension]: { [questionId]:answerValue } }
 * @param rawQuestions Unprocessed questions that come from the database
 */
export const createAnswers = (rawQuestions) => {
  let initialAnswers = {};
  for (let question of rawQuestions) {
    if (question.dimesion in initialAnswers) {
      initialAnswers[question.dimension][question.questionID] = null;
    } else {
      initialAnswers[question.dimension] = {
        ...initialAnswers[question.dimension],
        [question.questionID]: null,
      };
    }
  }
  return initialAnswers;
};

/**
 * Retrieves answers of registered users
 */
export const retrieveAnswers = async () => {
  try {
    const response = await API({
      url: "results/retrieve",
      method: "GET",
    });
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

/**
 * Processes questions so that it is compatible with the QuestionTable component
 * @param rawAnswers answers retrived from the database
 */
export const processAnswers = (rawAnswers) => {
  let processedAnswers = {};
  for (let saveAnswers of Object.values(rawAnswers)) {
    processedAnswers[saveAnswers.dimension] = {
      ...processedAnswers[saveAnswers.dimension],
      [saveAnswers.question_id]: saveAnswers.answer,
    };
  }
  return processedAnswers;
};

/**
 * Checks if there is no answer for all question
 * @param answers the answers of the survey
 */
export const isEmpty = (answers) => {
  if (answers === null) return true;
  for (let dimensionAnswers of Object.values(answers)) {
    for (let answer of Object.entries(dimensionAnswers)) {
      if (answer[1] != null) return false;
    }
  }
  return true;
};
