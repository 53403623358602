import React, { useState, useEffect } from "react";

import { NUM_DIMENSION } from "../../constants/constants.data";

import Slides from "./slide/slide.component";

import "./carousel.styles.css";

/**
 * Display user results with corresponding resources
 * @param {Array<Object>} resources Array of resources for the user
 * @param {Array<number>} formatData Array of formatted score data
 * @param {Array<UserResult>} userResults Processed array of user results
 */
const Carousel = ({ resources, formatData, userResults }) => {
  // Index of the current slide
  const [slideIndex, setSlideIndex] = useState(0);
  // Array of dots to indicate slide index
  const DOTS = document.getElementsByClassName("dot");

  /**
   * Make the active slide dot blue
   */
  useEffect(() => {
    DOTS[slideIndex].className += " active";
  }, [slideIndex, DOTS]);

  /**
   * Sets slideIndex according to where the user clicks (i.e. dots or the next/prev buttons)
   * @param {number} step Increment to move the slideIndex by
   * @param {boolean} isDot Value indicates if it is a dot that was clicked
   */
  const changeSlide = (step, isDot = false) => {
    DOTS[slideIndex].className = "dot";

    if (!isDot) {
      slideIndex === 0 && step === -1
        ? setSlideIndex(NUM_DIMENSION)
        : setSlideIndex(
            (prevSlideIndex) => (prevSlideIndex + step) % (NUM_DIMENSION + 1)
          );
    } else {
      setSlideIndex(step);
    }
  };
  /**
   * Store the dots in an array which will be displayed
   * on when rendered
   */
  let dots = [];
  for (let slide = 0; slide <= NUM_DIMENSION; slide++) {
    dots.push(
      <span
        key={slide}
        className="dot"
        onClick={() => changeSlide(slide, true)}
      ></span>
    );
  }

  return (
    <div className="slideshow-container">
      <div className="prev" onClick={() => changeSlide(-1)}>
        <i className="fa fa-chevron-left" aria-hidden="true"></i>
      </div>
      <Slides
        slideIndex={slideIndex}
        resources={resources}
        formatData={formatData}
        userResults={userResults}
        changeSlide={changeSlide}
      />
      <div className="next" onClick={() => changeSlide(1)}>
        <i className="fa fa-chevron-right " aria-hidden="true"></i>
      </div>

      <div className="dot-container">{dots}</div>
    </div>
  );
};

export default Carousel;
