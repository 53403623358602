import React from "react";
import { connect } from "react-redux";

import { generatePDF } from "../../utils/pdf-extraction/generatePDF.utils";

import API from "../../utils/api.utils";

import Carousel from "../carousel/carousel.component";
import CarouselChart from "../chart/carousel-chart.component";
import WithSpinner from "../with-spinner/with-spinner.component";

/**
 * This component serves as a container for the contents
 * of the Results page.
 * @param {Array<Object>} resources Array of resources for the user
 * @param {Array<number>} formatData Array of formatted score data
 * @param {Array<UserResult>} userResults Processed array of user results
 * @param {string} identity Identity group chosen by user
 */
const ResultContent = ({ resources, formatData, userResults, identity }) => {
  const addDownloads = async () => {
    try {
      await API({
        url: "analytics/addDownloaded",
        method: "POST",
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <button
        className="btn-lg btn-primary"
        onClick={() => {
          generatePDF(resources, identity, userResults);
          addDownloads();
        }}
      >
        Download Results
      </button>
      <Carousel
        resources={resources}
        formatData={formatData}
        userResults={userResults}
      />
      {/**
       * The chart below is hidden but need to be rendered for the PDF
       */}
      <CarouselChart formatData={formatData} print={true} />
    </>
  );
};

const mapStateToProps = ({ user, surveyData: { identity } }) => ({
  user,
  identity,
});

export default WithSpinner(connect(mapStateToProps, null)(ResultContent));
