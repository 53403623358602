import * as Measurements from "./measurements.data";
import { SLIDE_CONTENT } from "../../components/carousel/slide/slide.data";

import {
  CONCORDIA_LOGO_SRC,
  CONCORDIA_LOGO_ALT,
} from "../../constants/constants.data";

export var verticalPosition;
export var lastLineInAdjustText;
let pageNumber = 1;

//FORMAT: dd/mm/yyyy
const DATE_OBJ = new Date();
const DATE =
  DATE_OBJ.getDate().toString() +
  "/" +
  ((DATE_OBJ.getMonth() % 12) + 1).toString() +
  "/" +
  DATE_OBJ.getFullYear().toString();

/**
 * This function helps adjust the text such that it does not overflow horizontally
 * @param text
 * @param MAX_CHAR_IN_LINE
 */
export const adjustText = (
  text,
  MAX_CHAR_IN_LINE = Measurements.DEFAULT_MAX_CHAR_IN_LINE
) => {
  let lines = [""];
  let currentLineIndex = 0;
  const wordSplitted = text.split(" ");

  wordSplitted.forEach((element) => {
    //Store the temptative number of char in line
    let verifyLength = lines[currentLineIndex].length + element.length;

    //If the word contains a "\n", add newline to line and start a new line
    if (element.includes("\n")) {
      lines[currentLineIndex] += element.trim();
      lines[currentLineIndex] += "\n\n";
      currentLineIndex += 1;
      lines[currentLineIndex] = "";
      //If temptative new word does not overflow MAX_CHAR_IN_LINE, concat the new word,
      //else start a new line and make it the first word of the new line
    } else if (verifyLength <= MAX_CHAR_IN_LINE) {
      lines[currentLineIndex] += element + " ";
    } else {
      lines[currentLineIndex] += "\n";
      currentLineIndex += 1;
      lines[currentLineIndex] = "";
      lines[currentLineIndex] += element + " ";
    }
  });

  //Concat the lines array and return the adjusted text
  let adjustText = "";
  lines.forEach((element) => {
    adjustText += element;
  });

  lastLineInAdjustText = lines[lines.length - 1];

  return adjustText;
};

/**
 * Modifies the current vertical position and returns the old position.
 * @param adjustBy
 * @param doc jsPDF doc object, Only needed in case of page overflow,
 *            IMPORTANT to factor page overflow, the doc object must be provided
 */
export const mutateVerticalPostion = (
  adjustBy = Measurements.DEFAULT_SPACING,
  doc = null
) => {
  //Page overflow
  if (
    doc !== null &&
    verticalPosition + adjustBy >=
      Measurements.PAGE_HEIGHT - Measurements.BOTTOM_MARGIN_DEF
  ) {
    newPage(doc);
    doc.setTextColor(Measurements.BLUE);
    doc.setFontSize(Measurements.REGULAR_SIZE);
    return verticalPosition;
  } else {
    //No page overflow
    let currentLocation = verticalPosition;
    verticalPosition += adjustBy;
    return currentLocation;
  }
};

/**
 * Sets the verticalPosition to initial value
 */
export const resetVerticalPosition = () => {
  verticalPosition = Measurements.RESET_VERT_POS;
};

/**
 * Adds a new page with header and footer and resets the vertical position tracker.
 * @param doc
 */
export const newPage = (doc) => {
  doc.setTextColor(Measurements.BLACK);
  doc.addPage();
  formatHeader(doc);
  formatFooter(doc);
  doc.setFontSize(Measurements.REGULAR_SIZE);
  resetVerticalPosition();
};

/**
 * Set initial doc params
 * @param doc
 */
export const setInitialDocParams = (doc) => {
  verticalPosition = Measurements.INITIAL_VERT_POS;
  doc.setFont(Measurements.FONT);
  formatHeader(doc);
  formatFooter(doc);
  doc.setFontSize(Measurements.MAIN_HEADER_SIZE);
};

/**
 * Formats and displays header content
 * @param doc
 */
export const formatHeader = (doc) => {
  doc.setFontSize(Measurements.HEADER_FONT_SIZE);
  if (pageNumber === 1) {
    let logoImage = new Image();
    logoImage.src = CONCORDIA_LOGO_SRC;
    logoImage.alt = CONCORDIA_LOGO_ALT;
    doc.addImage(
      logoImage,
      "PNG",
      0,
      0,
      Measurements.CONCORDIA_LOGO_LENGTH,
      Measurements.CONCORDIA_LOGO_WIDTH
    );
  } else {
    doc.text(
      "Digital Capibilities - Self Assessment",
      Measurements.MARGIN_LEFT,
      Measurements.MARGIN_TOP
    );
  }
  doc.text(DATE, Measurements.MARGIN_LEFT * 18, Measurements.MARGIN_TOP);
};

/**
 * Formats and displays header content
 * @param doc
 */
export const formatFooter = (doc) => {
  doc.setFontSize(Measurements.FOOTER_FONT_SIZE);
  doc.text(
    (pageNumber++).toString(),
    Measurements.MARGIN_RIGHT,
    Measurements.MARGIN_BOTTOM
  );
};

/**
 * Formats and displays the result chart
 * @param doc
 */
export const formatChartResult = (doc) => {
  try {
    let newCanvas = document.getElementById("radar-print");
    let newCanvasImg = newCanvas.toDataURL();
    doc.addImage(
      newCanvasImg,
      "PNG",
      Measurements.CENTER_CHART,
      mutateVerticalPostion(Measurements.CHART_VERTICAL_ADJUSTMENT),
      Measurements.WIDTH_CHART,
      Measurements.HEIGHT_CHART
    );
  } catch (e) {
    console.log(e);
  }
};

/**
 * Formats and displays the content of a dimension
 * @param doc
 * @param resources
 * @param dimension
 */
export const formatDimension = (doc, resources, dimension = 1, level) => {
  const MAX_CHAR_LIST_LINE = 73;
  const MAX_CHAR_HEADER_LINE = 62;

  doc.setFontSize(Measurements.DIMENSION_HEADER_SIZE);
  doc.setFontStyle("bold");
  doc.setTextColor(Measurements.WHITE);
  doc.setFillColor(SLIDE_CONTENT[dimension].color);
  let shortTitle =
    SLIDE_CONTENT[dimension]["header"].length < MAX_CHAR_HEADER_LINE;

  //Colored rectangle for header
  doc.roundedRect(
    Measurements.LEFT_MARGIN_HEADER,
    mutateVerticalPostion(
      shortTitle
        ? Measurements.SHORT_TITLE_BOX_VERT_SPACING
        : Measurements.LONG_TITLE_BOX_VERT_SPACING
    ),
    Measurements.PAGE_WIDTH - 2 * Measurements.LEFT_MARGIN_HEADER,
    Measurements.BOX_HEIGHT,
    Measurements.BOX_RADIUS,
    Measurements.BOX_RADIUS,
    "F"
  );

  //Header dimension text
  doc.text(
    adjustText(SLIDE_CONTENT[dimension]["header"], MAX_CHAR_HEADER_LINE),
    Measurements.LEFT_MARGIN_DEF,
    mutateVerticalPostion(
      shortTitle
        ? Measurements.SHORT_TITLE_HEADER_SPACING
        : Measurements.LONG_TITLE_HEADER_SPACING
    )
  );
  doc.setFontSize(Measurements.LEVEL_ASSESSED_FONT_SIZE);

  doc.setTextColor(Measurements.BLACK);

  doc.setFontStyle("normal");

  //Level Assessed text
  doc.text(
    adjustText("Level Assessed:"),
    Measurements.BULLET_MARGIN,
    mutateVerticalPostion(Measurements.LEVEL_ASSESSED_SPACING)
  );
  doc.setFontStyle("bold");
  let levelAssessedHoriPosition = doc.getTextWidth(lastLineInAdjustText) - 0.3;
  let errorAlignment = Measurements.LEVEL_ASSESSED_SPACING;

  doc.text(
    level,
    Measurements.BULLET_MARGIN + levelAssessedHoriPosition,
    verticalPosition - errorAlignment
  );

  //Dimension descriptors
  doc.setFontSize(Measurements.REGULAR_SIZE);
  doc.text(
    adjustText("This dimension covers your ability to:"),
    Measurements.BULLET_MARGIN,
    mutateVerticalPostion(Measurements.LIST_ITEM_SPACING)
  );

  doc.setFontStyle("normal");

  SLIDE_CONTENT[dimension]["description"].forEach((item) => {
    //Bullet point
    doc.circle(
      Measurements.BULLET_MARGIN,
      mutateVerticalPostion(Measurements.BULLET_VERT_ADJUSTMENT),
      Measurements.BULLET_RADIUS,
      "F"
    );

    let newText = adjustText(item, MAX_CHAR_LIST_LINE);
    doc.text(
      newText,
      Measurements.AFTER_BULLET,
      mutateVerticalPostion(Measurements.LIST_ITEM_SPACING)
    );

    //If line wrap, adjust line spacing
    if (newText.length - 1 > MAX_CHAR_LIST_LINE) {
      mutateVerticalPostion(Measurements.LIST_ITEM_SPACING);
    }
  });

  //Adjust vertical position and store it for the image's height
  let imageVerticalPos =
    mutateVerticalPostion(Measurements.LIST_ITEM_SPACING) -
    Measurements.IMAGE_OFFSET;

  //Insert dimension image at the end of the description list
  let logoImage = new Image();
  logoImage.src = SLIDE_CONTENT[dimension]["image"];
  logoImage.alt = SLIDE_CONTENT[dimension]["alt"];
  doc.addImage(
    logoImage,
    "PNG",
    Measurements.IMAGE_LEFT_MARGIN,
    imageVerticalPos,
    Measurements.IMAGE_SIZE,
    Measurements.IMAGE_SIZE
  );

  doc.setFontStyle("bold");

  //Dimension resources
  doc.text(
    adjustText("You might be intersted in the following resources:"),
    Measurements.BULLET_MARGIN,
    mutateVerticalPostion(Measurements.LIST_ITEM_SPACING, doc)
  );

  doc.setFontStyle("normal");

  resources.forEach((row) => {
    doc.setFillColor(Measurements.BLACK);
    if (row["dimension"] === dimension) {
      doc.circle(
        Measurements.BULLET_MARGIN,
        mutateVerticalPostion(Measurements.BULLET_VERT_ADJUSTMENT, doc),
        Measurements.BULLET_RADIUS,
        "F"
      );

      let newText = adjustText(row["resource"], MAX_CHAR_LIST_LINE);
      doc.setTextColor(Measurements.BLUE);
      doc.textWithLink(
        adjustText(row["resource"], MAX_CHAR_LIST_LINE),
        Measurements.AFTER_BULLET,
        mutateVerticalPostion(Measurements.LIST_ITEM_SPACING),
        {
          url: row["link"],
        }
      );

      //If line wrap, adjust line spacing
      if (newText.length - 1 > MAX_CHAR_LIST_LINE) {
        mutateVerticalPostion(Measurements.LIST_ITEM_SPACING);
      }
    }
  });

  //Adjust spacing between dimension blocks
  mutateVerticalPostion(Measurements.DIMENSION_SPACING);

  doc.setTextColor(Measurements.BLACK);
};

/**
 * Resets the page number to 1
 */
export const resetPageNumber = () => {
  pageNumber = 1;
};
