import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import API from "../../../utils/api.utils";
import { toggleModal, setPurpose } from "../../../redux/modal/modal.actions";

import "./nav-menu-item.styles.css";

/**
 * This function makes the nav bar responsive depending on the screen size
 */
const responsiveNav = () => {
  let headerNav = document.getElementById("topNav");

  if (headerNav.className === "topNav") {
    headerNav.className += " responsive";
  } else {
    headerNav.className = "topNav";
  }
};

/**
 * The first key is when the nav item should be active
 * e.g. "Save progress" nav item will appear on path "/question"
 */
const navBarItems = [
  { "/question": { content: "Save progress", redirect: "/register" } },
  { "/question": { content: "Exit Survey", redirect: "/exit" } },
  { "/results": { content: "Done", redirect: "/exit" } },
  { "/register": { content: "Return", redirect: "/question" } },
  { "/login": { content: "Return", redirect: "/" } },
  { "/exit": { content: "Home", redirect: "/" } },
  { "/successfulsave": { content: "Home", redirect: "/" } },
  { "/info": { content: "Home", redirect: "/" } },
];

/**
 * Display nav bar content correspond to user page
 * @param {() => void} toggleModal Toggles to show/hide the modal
 * @param {(string) => void} setPurpose Sets the purpose of the modal
 */
const NavMenu = ({
  toggleModal,
  setPurpose,
  user,
  answers,
  furthestStep,
  currentStep,
}) => {
  /**
   * Show exit warning modal, and always close responsive nav
   * @param {string} content Purpose of the modal
   */
  const handleNavClick = async (content) => {
    //Closes navbar on click (for responsive navbar)
    responsiveNav();

    if (content === "Exit Survey" || content === "Done") {
      setPurpose("exitWarning");
      toggleModal();
    }
    if (content === "Save progress" && user.currentUser) {
      try {
        await API({
          method: "POST",
          url: "/user/save",
          data: {
            answers,
            furthestStep,
          },
        })
          .then(() => setPurpose("save"))
          .catch(() => setPurpose("unsuccessfulsSave"))
          .finally(() => toggleModal());
      } catch (err) {
        console.log(err);
      }
    }
  };

  /**
   * Selects the path to redirect
   * @param {string} currentPath Current URL path that the user is on
   * @param {string} redirectPath URL path to redirect to
   * @param {string} content Purpose of the modal
   */
  const handleRedirect = (currentPath, redirectPath, content) => {
    //Stay on the same path
    if (content === "Exit Survey" || content === "Done") {
      return currentPath;
    }
    if (content === "Save progress" && user.currentUser) {
      return currentPath;
    }
    return redirectPath;
  };

  let menuItems = navBarItems.map((item, key) => {
    let currentPath = Object.keys(item)[0];
    let redirectPath = item[currentPath]["redirect"];
    let content = item[currentPath]["content"];

    return (
      <NavLink
        key={key}
        //true means "do not show", false means "show"
        isActive={() => {
          // currentStep = 0 when user is on the before we begin page
          if (content === "Save progress" && currentStep === 0) {
            return true;
          } else if (currentPath === window.location.pathname) {
            return false;
          } else {
            return true;
          }
        }}
        onClick={async () => handleNavClick(content)}
        to={() => handleRedirect(currentPath, redirectPath, content)}
        activeClassName="navItem"
      >
        <div id="navItems">{content}</div>
      </NavLink>
    );
  });

  return (
    <div className="topNav" id="topNav">
      <div className="empty">
        {/*Left empty on purpose such that the list starts from under the header*/}
      </div>
      {menuItems}
      <div className="icon" onClick={responsiveNav}>
        <i className="w3-xxlarge fa fa-bars"></i>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  user,
  surveyData: { answers, furthestStep, currentStep },
}) => ({
  user,
  answers,
  furthestStep,
  currentStep,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModal: () => dispatch(toggleModal()),
  setPurpose: (purpose) => dispatch(setPurpose(purpose)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);
