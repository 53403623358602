// Identity Groups for Before We Begin Page
export const IDENTITIES_GROUPS = [
  { value: "undergraduate", text: "Undergraduate" },
  { value: "graduate", text: "Graduate" },
  { value: "faculty", text: "Faculty" },
  { value: "staff", text: "Staff" },
];

// Levels for Before We Begin Page
export const LEVELS = [
  { value: "1", text: "1 (Novice)" },
  { value: "2", text: "2" },
  { value: "3", text: "3 (Intermediate)" },
  { value: "4", text: "4" },
  { value: "5", text: "5 (Advanced)" },
];
