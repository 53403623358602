// The maximum number of dimensions for the digital capabilities
export const NUM_DIMENSION = 12;
// Responsive width
export const RESPONSIVE_WIDTH = 800;
/**
 * The minimum completed survey for a group, used to show or not show
 * the average scores of the user corresponding group.
 */
export const MIN_COMPLETED_SURVEYS_IN_GROUP = 20;
// Fill color of radar chart
export const RADAR_FILL_COLOR = `rgba(80, 182, 255, 0.6)`;
// Fill color of average radar chart
export const AVERAGE_FILL_COLOR = `rgba(255, 36, 54, 0.6)`;
// Goverment framework
export const DIGITAL_FRAMEWORK_LINK =
  "http://www.education.gouv.qc.ca/fileadmin/site_web/documents/ministere/Cadre-reference-competence-num-AN.pdf";

// Concordia logo
export const CONCORDIA_LOGO_SRC = "/images/logo-concordia-university.png";
export const CONCORDIA_LOGO_ALT = "Concordia Logo";
