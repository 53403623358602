import { SurveyDataActionTypes } from "./survey-data.types";

export const setAnswers = (answers) => ({
  type: SurveyDataActionTypes.SET_ANSWERS,
  payload: answers,
});

export const setIdentity = (identity) => ({
  type: SurveyDataActionTypes.SET_IDENTITY,
  payload: identity,
});

export const setLevel = (level) => ({
  type: SurveyDataActionTypes.SET_LEVEL,
  payload: level,
});

export const setCurrentStep = (currentStep) => ({
  type: SurveyDataActionTypes.SET_CURRENT_STEP,
  payload: currentStep,
});

export const setFurthestStep = (furthestStep) => ({
  type: SurveyDataActionTypes.SET_FURTHEST_STEP,
  payload: furthestStep,
});
