import { ModalActionTypes } from "./modal.types";

export const toggleModal = () => ({
  type: ModalActionTypes.TOGGLE_MODAL,
});

export const setPurpose = (purpose) => ({
  type: ModalActionTypes.SET_PURPOSE,
  payload: purpose,
});
