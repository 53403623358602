import * as jsPDF from "jspdf";
import { SLIDE_CONTENT } from "../../components/carousel/slide/slide.data";
import { NUM_DIMENSION } from "../../constants/constants.data";
import { supportText, groupDependentText } from "./text.data";
import * as Measurements from "./measurements.data";
import {
  setInitialDocParams,
  adjustText,
  mutateVerticalPostion,
  formatChartResult,
  newPage,
  formatDimension,
  verticalPosition,
  resetPageNumber,
  lastLineInAdjustText,
} from "./pdf.functions";

//Documention on how to use jsPDF
// https://rawgit.com/MrRio/jsPDF/master/docs/
// https://github.com/MrRio/jsPDF/issues/170#issuecomment-293975156
//Demo
// https://rawgit.com/MrRio/jsPDF/master/

//This function formats and generates a PDF corresponding to the
//user's results.
export const generatePDF = (resources, identity, userResults) => {
  try {
    let doc = new jsPDF(Measurements.jsDOC);

    setInitialDocParams(doc);

    //Title
    doc.text(
      adjustText("Digitial Capabilities - Self Assessment"),
      Measurements.LEFT_MARGIN_DEF,
      mutateVerticalPostion(Measurements.TITLE_VERT_SPACING)
    );

    doc.setFontSize(Measurements.REGULAR_SIZE);

    //Sub-Title text
    doc.text(
      adjustText(
        `Below you will find your proficiency level for each of the ${NUM_DIMENSION} dimensions of the digital capabilities` +
          " framework. Use these results to help guide you towards developing your skills in accordance to your" +
          " needs and interests."
      ),
      Measurements.LEFT_MARGIN_DEF,
      mutateVerticalPostion(Measurements.INTRO_PARA_SPACING_1)
    );

    //Chart
    formatChartResult(doc);

    doc.setFontStyle("bold");

    //Sub-chart header
    doc.text(
      adjustText(
        "Here are some resources that can help you develop some" +
          " of your digital capabilities"
      ),
      Measurements.LEFT_MARGIN_DEF,
      mutateVerticalPostion(Measurements.INTRO_PARA_SPACING_2)
    );

    doc.setFontStyle("normal");
    doc.setFontSize(Measurements.REGULAR_SIZE);

    //User group respective text
    //Determine require vertical adjustment based on the number of line
    //the text contains.
    let adjustedGroupText = adjustText(groupDependentText[identity]["text"]);
    let countNumberOfNewLine = (adjustedGroupText.match(/\n/g) || []).length;

    doc.text(
      adjustedGroupText,
      Measurements.LEFT_MARGIN_DEF,
      mutateVerticalPostion(
        countNumberOfNewLine * Measurements.NEW_LINE_MULTIPLIER +
          Measurements.PARA_SPACING
      )
    );

    doc.setTextColor(Measurements.BLUE);

    //Since jsPDF does not support in-text link and links are their own text,
    //must calculate where it goes
    let linkHoriPosition = doc.getTextWidth(lastLineInAdjustText);
    let hyperLinkText = "Learn more!";
    let hyperLinkTextWidth = doc.getTextWidth(hyperLinkText);
    let errorAlignment = 1.03;

    let hyperLinkOnNewLine =
      Measurements.PAGE_WIDTH - 2 * Measurements.LEFT_MARGIN_DEF <=
      linkHoriPosition + hyperLinkTextWidth;

    if (hyperLinkOnNewLine) {
      mutateVerticalPostion(Measurements.HYPERLINK_NEWLINE_SPACING);
    }
    //User group respective link
    doc.textWithLink(
      hyperLinkText,
      hyperLinkOnNewLine
        ? Measurements.LEFT_MARGIN_DEF
        : Measurements.LEFT_MARGIN_DEF + linkHoriPosition,
      hyperLinkOnNewLine
        ? verticalPosition - Measurements.PARA_SPACING
        : verticalPosition - errorAlignment,
      {
        url: groupDependentText[identity]["link"],
      }
    );

    doc.setTextColor(Measurements.BLACK);

    //Support text and link that is used for any user group
    let didAddPage = false;
    for (let i = 1; i <= Object.keys(supportText).length; i++) {
      //Determine require vertical adjustment based on the number of line
      //the text contains.
      let adjustedSupportText = adjustText(supportText[i]["text"]);
      let _countNumberOfNewLine = (adjustedSupportText.match(/\n/g) || [])
        .length;

      doc.text(
        adjustedSupportText,
        Measurements.LEFT_MARGIN_DEF,
        mutateVerticalPostion(
          _countNumberOfNewLine * Measurements.NEW_LINE_MULTIPLIER +
            Measurements.PARA_SPACING
        )
      );

      doc.setTextColor(Measurements.BLUE);

      //Since jsPDF does not support in-text link and links are their own text,
      //must calculate where it goes
      linkHoriPosition = doc.getTextWidth(lastLineInAdjustText);
      hyperLinkOnNewLine =
        Measurements.PAGE_WIDTH - 2 * Measurements.LEFT_MARGIN_DEF <=
        linkHoriPosition + hyperLinkTextWidth;

      doc.textWithLink(
        hyperLinkText,
        hyperLinkOnNewLine
          ? Measurements.LEFT_MARGIN_DEF
          : Measurements.LEFT_MARGIN_DEF + linkHoriPosition,
        hyperLinkOnNewLine
          ? verticalPosition - Measurements.PARA_SPACING / 2
          : verticalPosition - errorAlignment,
        {
          url: supportText[i]["link"],
        }
      );

      if (hyperLinkOnNewLine) {
        mutateVerticalPostion(Measurements.HYPERLINK_NEWLINE_SPACING);
      }

      doc.setTextColor(Measurements.BLACK);

      //Start a new page to avoid overflow
      if (
        Measurements.PAGE_HEIGHT - verticalPosition <=
          Measurements.PAGE_HEIGHT / 12 &&
        !didAddPage
      ) {
        didAddPage = true;
        newPage(doc);
      }
    } // end loop

    //Avoid having an almost blank page
    if (!didAddPage) newPage(doc);

    //Dimension content
    for (let dimension = 1; dimension < SLIDE_CONTENT.length; dimension++) {
      formatDimension(
        doc,
        resources,
        dimension,
        userResults[dimension - 1]["level"]
      );

      //Start a new page to avoid overflow
      if (
        verticalPosition >= 2 * (Measurements.PAGE_HEIGHT / 3) &&
        dimension < SLIDE_CONTENT.length - 1 //Avoids a last empty page
      ) {
        newPage(doc);
      }
    }

    //Reset page counter
    resetPageNumber();

    // Download PDF
    const RESULT_FILENAME = "digital-capabilities-results.pdf";

    // Download PDF
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // Create Blob, a file-like object of immutable raw data.
      let blob = URL.createObjectURL(doc.output("blob"));
      downloadBlob(blob, RESULT_FILENAME);
      // Delete Blob
      setTimeout(() => {
        URL.revokeObjectURL(blob);
      }, 60000); //One minute
    } else {
      doc.save(RESULT_FILENAME);
    }
  } catch (e) {
    console.log(e);
  }
};

/**
 * Downloads the pdf file, compatible with all devices
 * jsPPDF's way to download the pdf is by doing: doc.save("filename.pdf")
 * Orignal: https://dev.to/nombrekeff/download-file-from-blob-21ho
 *
 * @param blob Blob Object for the PDF
 * @param name Filename given
 */
const downloadBlob = (blob, name) => {
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  link.href = blob;
  link.download = name;

  document.body.appendChild(link);

  // Dispatch click event on the link
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  document.body.removeChild(link);
};
